.Deviations-header {
  height: 64px;
  opacity: 1;
  border-top: 0.5px solid #DFE8F0;
  font: Bold 14px Montserrat;
  justify-content: center;
  align-items: center;
}

.Deviations-header-text {
  font: bold 16px Montserrat;
  color: #65778B;
  opacity: 1;
}

.Deviations-content-text {
  font: Bold 14px Montserrat;
  color: #65778B;
  opacity: 1;
}

.Deviations-content-text-date {
  font: normal 12px Montserrat;
  color: #65778B;
  opacity: 1;
}

.mAuto {
  margin: auto;
}

.Deviations-flex {
  display: flex;
}