.buttonStyle {
  width: 40px;
  height: 39px;
  border-radius: 4px;
  opacity: 1;
}

.bottomBrdr {
  border-bottom: 1px solid #f4f5f5;
}

.bottomBrdrHeader {
  border-bottom: 1px solid #e7ecf2;
}

.mt-5 {
  margin-top: 5px;
}

.titleText {
  text-align: center;
  font: Bold 20px Lato;
  letter-spacing: 0;
  color: #2d2d2d;
  padding-top: 8px;
  padding-bottom: 5px;
  opacity: 1;
}

/* .divider {
  height: 24px;
  border: 1px solid #b6cadd;
  opacity: 1;
  margin: 15px 15px;
} */

/* .dividerB {
  height: 20px;
  border: 1px solid #2d2d2d;
  opacity: 1;
  margin: 18px 8px;
} */

.reportBoxBank {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 70px #d6e0ea58;
  border: 1px solid #92d1c081;
  border-radius: 4px;
  opacity: 1;
  padding: 10px 40px;
}

.reportBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e7ecf2;
  border-radius: 4px;
  opacity: 1;
  padding: 10px 20px;
}

.reportText {
  text-align: left;
  font: Bold 18px Lato;
  letter-spacing: 0;
  color: #838b8b;
  opacity: 1;
}

.reportText img {
  height: 35px;
}

.reportTextBankName {
  text-align: left;
  font: Bold 16px Lato;
  letter-spacing: 0;
  color: #838b8b;
  opacity: 1;
  margin-top: 27px;
}

.pdfText {
  text-align: left;
  font: Bold 16px/72px Lato;
  letter-spacing: 0;
  color: #828282;
  opacity: 1;
}

.width100 {
  width: 80%;
}

.downloadText {
  text-align: left;
  font: Bold 18px Lato;
  letter-spacing: 0;
  color: #30ad76;
  opacity: 1;
}

.dividerRP {
  height: 39px;
  border: 1px solid #dddddd;
  opacity: 1;
  margin: 17px 17px 17px 17px;
}

.loadMoreText {
  text-align: center;
  letter-spacing: 0;
  color: #828282;
  opacity: 1;
}

.loadMoreText:hover {
  cursor: pointer;
}

input {
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 50px;
  padding: 0 8px;
  border-radius: 4px;
  background-color: #fbfbfb;
  border: 1px solid #eff2f3;
  opacity: 1;
}

.tagsKey-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 70px;
  padding: 10px 8px;
  border-radius: 4px;
  background-color: #fafbfc58;
  border: 1px solid #eff2f3;
  opacity: 1;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag-title {
  margin-top: 0px;
}

.tag {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px 6px 10px;
  font: Bold 11px Raleway;
  list-style: none;
  font-weight: bold;
  border-radius: 104px;
  margin: 0 15px 8px 0;
  background-color: #ffffff;
  color: #828282;
  border: 1px solid #eaeced;
}

.tagKey {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 15px 6px 10px;
  font: ExtraBold 13px Raleway;
  font-weight: bold;
  list-style: none;
  border-radius: 104px;
  margin: 0 8px 8px 0;
  background-color: #ffffff;
  color: #009068;
  border: 1px solid #eaeced;
}

.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 5px;
  color: #828282;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.tagKey-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 5px;
  color: #009068;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: 100%;
  }
}

.rightPanelBox {
  height: 275px;
  background: #fdfeff 0% 0% no-repeat padding-box;
  border: 1px solid #c2e3dc;
  border-radius: 4px;
  opacity: 1;
  overflow: auto;
}

.progressBar {
  background-color: #e6e4e4 !important;
  height: 3px;
}

.progress-bar {
  background-color: #16ba84 !important;
  height: 3px;
}

.progressText {
  text-align: left;
  font-size: 18px;
  font-family: Lato;
  font-weight: bold;
  letter-spacing: 0;
  color: #717684;
  opacity: 1;
}

.progressDate {
  text-align: left;
  letter-spacing: 0;
  font-size: 15px;
  font-family: Lato;
  font-weight: bold;
  color: #24a0ac;
  opacity: 1;
}

.selectText {
  text-align: left;
  font-size: 14px;
  font-family: Lato;
  font-weight: bold;
  letter-spacing: 0;
  color: #b4bac3;
}

.keywordText {
  text-align: left;
  font-size: 16px;
  font-family: Lato;
  font-weight: bold;
  letter-spacing: 0;
  color: #3d4a71;
  opacity: 1;
}

.css-1hwfws3 {
  padding: 0 !important;
}

.css-yk16xz-control {
  border: 0px !important;
  border-bottom: 1px solid #e7ecf2 !important;
  border-radius: 0px !important;
}

.css-yk16xz-control:hover {
  background-color: transparent !important;
}

.css-yk16xz-control {
  background-color: transparent !important;
}

.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}

.input {
  outline: 0;
  border: 0px;
  border-bottom: 2px solid;
  border-color: #24a0ac;
}

.input:focus {
  border-color: #24a0ac;
}

.analyseBtn {
  width: 152px;
  height: 49px;
  background: #16ba84 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  font-weight: bold;
  font: Bold 19px Raleway;
}

.mt--8 {
  margin-top: -8px;
}

.eyeCircle {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  background: #cdcece 0% 0% no-repeat padding-box;
  opacity: 0.21;
}

.lockCircle {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  background: #b0dee3 0% 0% no-repeat padding-box;
  opacity: 0.21;
}

.dustCircle {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  background: #ffebeb 0% 0% no-repeat padding-box;
  opacity: 0.21;
}

.checkCircle {
  width: 43px;
  height: 43px;
  border-radius: 100px;
  border: 2px solid #24a0ad;
  opacity: 1;
}

.addNewBtn {
  height: 56px;
  background: #156c74 0% 0% no-repeat padding-box;
  box-shadow: 3px 8px 28px #00000019;
  border-radius: 4px;
  opacity: 1;
  color: white;
  font-weight: bold;
  font: Bold 14px Raleway;
  padding-top: 10px;
}

.addNewPlusBtn {
  width: 33px;
  height: 32px;
  opacity: 1;
  border-radius: 100px;
  color: white;
  font-size: 20px;
}

.addNewPlusText {
  color: white;
  font-size: 20px;
  margin-top: -3px;
}

.colorRed {
  color: red;
}

.bankReportText {
  text-align: left;
  font: Bold 22px Lato;
  letter-spacing: 0;
  color: #02444e;
  opacity: 1;
}

.rotateimg180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.modal-dialog {
  max-width: 635px;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  overflow: auto;
}

.loadMoreText {
  text-align: center;
  font: Bold 14px Lato;
  letter-spacing: 0;
  color: #828282;
  opacity: 1;
}

.inputSelectText {
  text-align: left;
  font: Bold 18px Lato;
  letter-spacing: 0;
  color: #575e71;
  opacity: 1;
}

.pb30 {
  /* padding: 20px 45px; */
}
.main-dcmt {
  background: #fff;
  padding: 20px 20px;
}
