.mainDiv {
    height: 80px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 24px #25265E05;
    border: 1px solid #F0F3F4;

}

.mainHeader {
    font: Bold 21px/63px Lato;
    letter-spacing: 0px;
    color: #5D6F83;
    opacity: 1;
}

.leftSide {
    width: 80px;
    background: #D2D2D229 0% 0% no-repeat padding-box;
    text-align: center;
    position: absolute;
}

.leftSide>img {
    width: 43px;
    height: 43px;
    background: #02444E 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: 20px;
}

.headDiv {
    background-color: #EFFDFF;
}

.fullBorder {
    border: 1px solid #E8E0DB;
}

.bottomBorder {
    border-bottom: 1px solid #E8E0DB;
}

.topBorder {
    border-top: 1px solid #E8E0DB;
}

.topBrdrNone {
    border-top: 0px;
}

.headerText {
    text-align: left;
    font: Bold 28px/13px Lato;
    letter-spacing: 0px;
    opacity: 1;
}

.headerText:hover {
    text-decoration: none;
}

.hrCss {
    width: 126px;
    height: 4px;
    background: #05444E 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    margin-bottom: 0px !important;
}

.nav-item {
    text-align: center;
}

.headrSwitch {
    text-align: right;
    font: Bold 24px/13px Lato;
    letter-spacing: 0px;
    color: #5D6F83;
    opacity: 1;
}

.pl80 {
    padding-left: 80px !important;
}

.yesText {
    margin-bottom: 0px;
    padding: 9px 0 0 11px;
    font: Bold 20px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.noText {
    margin-bottom: 0;
    padding: 9px 11px;
    font: Bold 20px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.sec2Text {
    text-align: left;
    font: Bold 18px Lato;
    letter-spacing: 0px;
    color: #52667C;
    opacity: 1;
}

.sec2Text2 {
    text-align: left;
    font: Bold 16px Lato;
    letter-spacing: 0px;
    color: #52667C;
    opacity: 1;
}

.sec2Btn {
    height: 50px;
    background: #FDFEFD 0% 0% no-repeat padding-box;
    border: 1px dashed #96A3B276;
    border-radius: 2px;
    opacity: 1;
}

.sec2Btn2 {
    height: 50px;
    background: #FDFEFD 0% 0% no-repeat padding-box;
    border: 1px dashed #FDFEFD;
    border-radius: 2px;
    opacity: 1;
}

.aiCenter {
    align-items: center;
}

.actColor {
    color: #05444E !important
}

.orangeColor {
    color: #C96D20;
}

.key-text {
    color: #859BB4;
    font: Bold 24px Lato;
}

.key-background {
    background-color: #fff7f2;
}

.lightColor {
    color: #5D6F83
}

.blueColor {
    color: #3F5CBA
}

.font26Bold {
    font: Bold 26px Lato;
    letter-spacing: 0px;
    opacity: 1;
}

.font34Bold {
    font: Bold 34px Lato;
    letter-spacing: 0px;
    color: #C96D20;
}

.nameBox {
    width: 260px;
    height: 98px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #FAFAFA;
    border: 1px solid #E8E0DB;
    /* border-right: 1px solid #E8E0DB; */
    margin-right: 10px;
}

.crbgColor {
    background-color: #FDF2EA;
}

.plusForm {
    padding: 20px 10px;
    align-items: center;
    display: flex;
}

.smallDivider {
    height: 30px;
    width: 2px;
    border-radius: 1px;
    background-color: #C96D20;
    margin-top: 15px;
}

.redCircle {
    width: 27px;
    height: 27px;
    border: 3px solid #F7605E;
    opacity: 1;
    border-radius: 13.5px;
}

.yellowCircle {
    width: 27px;
    height: 27px;
    border: 3px solid #DDDD44;
    opacity: 1;
    border-radius: 13.5px;
}

.greenCircle {
    width: 27px;
    height: 27px;
    border: 3px solid #3EBC6C;
    opacity: 1;
    border-radius: 13.5px;
}

.greenFillCircle {
    width: 27px;
    height: 27px;
    background: #3EBC6C 0% 0% no-repeat padding-box;
    border: 2px solid #3EBC6C;
    opacity: 1;
    border-radius: 13.5px;
}

.yellowFillCircle {
    width: 27px;
    height: 27px;
    background: #DDDD44 0% 0% no-repeat padding-box;
    border: 2px solid #DDDD44;
    opacity: 1;
    border-radius: 13.5px;
}

.redFillCircle {
    width: 27px;
    height: 27px;
    background: #F7605E 0% 0% no-repeat padding-box;
    border: 2px solid #F7605E;
    opacity: 1;
    border-radius: 13.5px;
}

.crText {
    font: Bold 30px Lato;
    letter-spacing: 0px;
    color: #7E6654;
    opacity: 1;
}

.greenColor {
    color: #1DB154
}

.leftRightBorder {
    border-left: 1px solid #E8E0DB;
    border-right: 1px solid #E8E0DB;
}

.darkGray {
    color: #5D6F83
}

.grayColor {
    color: #888888
}

.lightGray {
    color: #859BB4 !important
}

.lightGrayColor {
    color: lightgray
}

.blackColor {
    color: #000
}

.redColor {
    color: #F7605E
}

.divider1 {
    height: 3px;
    width: 30px;
    background-color: black;
    border-radius: 100px;
}

.dividerGray {
    height: 3px;
    width: 30px;
    background-color: gray;
    border-radius: 100px;
}

.aiCenter {
    align-items: center;
    display: flex;
}

.emailText {
    color: #2F72BE;
    text-decoration: underline;
}

p {
    word-wrap: break-word;
}

.modal-close-button {
    width: 99%;
    display: flex;
    justify-content: flex-end;
}

.close-text {
    margin-bottom: 0px;
    margin-top: 21px;
    font-size: 25px;
    margin-right: 13px;
}

.section-row {
    flex-flow: row;
}

.padBottom {
    padding-bottom: 15px;
}

@media screen and (max-width:1450px) {
    .topBorder .pb-4 .d-flex {
        display: inline-block !important;
        width: 100%;
    }
}

.image-view ::-webkit-scrollbar {
    width: 10px;
}

.image-view ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.image-view ::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
}

.image-view {
    display: flex;
    justify-content: center;
    width: auto;
    height: 400px;
}