.is-met{
  width:20%;
}

.is-met div {
  text-align: center !important;
    padding: 10px 40px;
    color: green;
  border: 1px solid green;
  border-radius: 4px;

  background-color: #F5FDFA;
}

.is-met-size{
  text-align: center;
  font: 700 14px Montserrat;
  color: #009265;
  opacity: 1;
 }

.is-unmet {
  background-color: #FFFAE9;
  
 }

 .is-default {  
  width:20%;
 }

.is-default div:first-child{
  text-align: center !important;
  color: #6B788E;
  border: 1.341px solid #6B788E;
  border-radius: 4px;
  padding: 10px 0;
 }
 
 .is-unmet-size{
  width:100%;
  padding: 10px 10px !important;
  text-align: center !important;  
  color: #F93737; 
  border: 1.341px solid #F93737;
  border-radius: 4px;
  background-color: #FFFAE9;
 }

 .is-unmet-size2{
  width:100%;  
  text-align: center !important;  
  color: #F93737; 
  border: 1.341px solid #F93737;
  border-radius: 4px; 
  background-color: #FFFAE9;
 }

 .unmet-icon{
  position: relative;
  top: 8px;
  left: 19px;  
 }

 .popover h3{
   padding-top:5px ;
   padding-bottom: 5px;
   font-size: 15px;
 }
 
  .popover {
    width: 300px !important;
    max-width: 300px !important; 
  }