
/*
.gstTable{
  color:#65778b;
  background-color: red;
}


*/
.viewData{
  font: Bold 10px Montserrat;
  color: #4478cb;
  font-size: 12px;
  /* padding-left: 16px; */
}
/*.bg .Gst-flex:nth-child(odd){
  background-color: #fefcfc;

}*/
.vl {
  border-left: 0.5px solid red;
   opacity: 1;

}
.primaryBusiness{
  background-color:#f8f4f4;
}


.Gst-text-main {
  
  font: Bold 20px Montserrat;
  letter-spacing: 0;
  color: #65778B;

}
.Gst-head{
   text-align: left;
   width: 292px;
  font: Bold 20px Montserrat;
  letter-spacing: 0;
  color: #65778B;

}
.Gst-text {
  text-align: left;
  font: Bold 14px Montserrat;
  letter-spacing: 0;
  color: #65778B;
  opacity: 1;
  text-transform: capitalize;
}
.Gst-text-data{
  font: Bold 14px Montserrat;
  letter-spacing: 0;
  color: #65778B;
  opacity: 1;
  padding-top: 10px;
  text-transform: capitalize;

}

.Gst-input-text {
  background: #FDFEFD 0% 0% no-repeat padding-box;
  border: 1px solid #96A3B2;
  border-radius: 2px;
  opacity: 0.44;
  width: 308px;
  height: 39px;
}

.Gst-flex{
  display: flex
}

.add{
  display:flex;
  font: Bold 12px Montserrat;
  padding-top: 10px;
  padding-bottom: 20px;
 
}
.add:link{
   color:#4478cb important;
   text-decoration:none; 
}

.regadd{
    /* text-align: left; */
    font: Bold 14px Montserrat;
    letter-spacing: 0;
    color: #65778B;
    /* padding-bottom:10px;
    padding-left: 16px; */
 
}
.overview{
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  padding-top: 20px;
  gap:30px;
}
.overviewData{
   display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-left: 16px;
  gap:10px;
}
.overviewMain{
  display: flex;
  flex-direction: column;
    padding-left: 16px;
}
.nob{
  background-color: white;
  width: 280px;
  padding-left: 20px;
  padding-top: 5px;

}
.nobone{
   width: 280px;
  padding-left: 20px;
  padding-top: 5px;
  color: #65778B;;

}
.nobfour{
    width: 280px;
  padding-left: 20px;
  padding-top: 5px;
  color: blue;
}

.gstFlex {
  display: flex;
}

.gstHeading {
  width: 20%;
  text-transform: capitalize;
  font: Bold 14px Montserrat;
  padding: 10px 5px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.gstFlex div.gstHeading:last-of-type {
  border-right: 1px solid #ddd;
}

.gstFlex div.gstHeadingData:last-of-type {
  border-right: 1px solid #ddd;
}

.gstHeadingData {
  width: 20%;
  padding: 10px 5px;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  color: #515151;
  font: normal 14px Montserrat;
}

.gstheadingData1,.gstheadingData2 {
  padding: 10px 5px;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  color: #515151;
  font: normal 14px Montserrat;
}

.gstheadingData2 {
  text-align: center;
}

.gstmainHeading {
  color: #333;
  padding: 40px 10px 10px 0;
  font: Bold 18px Montserrat;
}

.gstyear {
  color: #f27d32;
  border-bottom: 1px #f27d32 solid;
  text-align: right;
  padding-right: 0;
  font: Bold 15px Montserrat;
  margin-top: 20px;
  margin-bottom: 20px;
}


.gstheading2Inner {
  width: 20%;
  text-transform: capitalize;
  font: Bold 14px Montserrat;
  padding: 10px 5px;
   border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.gstheading2Gst {
  width: 40%;
   border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.gstheading2Gst:last-of-type {
  border-right: 1px solid #ddd;
}

.gstheading2GstInner1 {
  width: 100%;
  text-transform: capitalize;
  font: Bold 14px Montserrat;
  padding: 10px 5px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.gstheading2GstInner2 {
  width: 50%;
  text-transform: capitalize;
  font: Bold 14px Montserrat;
  padding: 10px 5px;
  text-align: center;
}

.gstheading2GstInner2:last-of-type {
  border-left: 1px solid #ddd;
}

.gstheadingData1 {
  width: 20%;
}

.gstheadingData1.txtBold {
  font-weight: bold;
}

.gstheadingData2 {
  width: 20%;
}

.gstFlex div.gstheadingData2:last-of-type {
  border-right: 1px solid #ddd;
}

.gstMain {
  padding-left: 30px;
  padding-bottom: 30px;
}
/* .viewData{

  color: #4478cb;
  font-size: 12px;
  padding-left: 16px;
} */
.bg .Gst-flex:nth-child(odd){
  background-color: #fefcfc;

}
.Gst-statusRed{
  color:red;
}
.Gst-statusGreen{
  color:#5cad8a;
}