.scoreboard {
  
  .hrMargin1 {
    margin-top: 39px !important;
  }
  .hrMargin {
    margin-top: 47px !important;
  }
  
  .scoreboard_card{
    min-height: 100%;
    background: #FFFFFF !important;
    border: 1px solid #d1d2d4;
    box-sizing: border-box;
    box-shadow: 0px 5.83033px 145.758px rgba(235, 238, 242, 0.05);
  }
  .scoreboard_card .pHead {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 17.491px;
    line-height: 20px;
    letter-spacing: 0.08em;
    text-transform: capitalize;
    color: #394353;
    margin: 0px 5.83033px;
}
    .big-font{
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 18.1575px;
      line-height: 18px;
      /* or 100% */

      text-align: center;

      color: #666C72;
    } 
    .smallFont{
      font-weight: bold;
      font-size: 9.87698px;
    }
    .small_score_font{
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
    
    .industry{
    .progress_fill_color{
      background-color: #91E8E0 !important;
      width: 70%;
      height: 10px ;
    }
    .progress_unfill_color{
      background-color: #BDC6C5 !important;
    }
  }
    .anchor{
      .progress_fill_color{
        background-color: #FDD774 !important;
        width: 70%;
        height: 10px ;
      }
      .progress_unfill_color{
        background-color: #BDC6C5 !important;
      }
    }

   
    .prospect-color{
      color:#666C72;
    }
  }


@media (min-width: 1281px) {
  
  /* CSS */
  .col-md-2-0 {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
  /* CSS */
  .col-md-2-0 {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  /* CSS */
  
  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  /* CSS */
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
  /* CSS */
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  /* CSS */
  
}