.Bureau-text-main {
  text-align: left;
  font: Bold 16px Montserrat;
  letter-spacing: 0;
  color: #65778B;
  opacity: 1;
}

.Bureau-text {
  text-align: left;
  font: Bold 14px Montserrat;
  letter-spacing: 0;
  color: #65778B;
  opacity: 1;
}

.Bureau-input-text {
  background: #FDFEFD 0% 0% no-repeat padding-box;
  border: 1px solid #96A3B2;
  border-radius: 2px;
  opacity: 0.44;
  width: 308px;
  height: 39px;
}

.Bureau-flex{
  display: flex
}
.Bureau .Bureau-flex:nth-child(odd){
  background-color: #fefcfc;
}