.loanSplitUP {
  margin-top: 20px;
}

.loanSplitUPGrap {
  color: #869AB1;
  font: normal 14px Montserrat;
}

.hlsuHead {
  margin-top: 40px;
  color: #DD803B;
  font: bold 20px Montserrat;
  text-transform: capitalize;
}
.hlsuMain:nth-child(odd){
  background-color: #FFFBF8;
}
.hlsuTableHead {
  display: flex;
}

.hlsuTable {
  margin: 20px;
}

.hlsuTableData{
  display: flex;
}

.hlsuTableHead div {
  width: 20%;
  color: #52667C;
  margin-bottom: 5px;
  padding: 10px;
  font: bold 13px Montserrat;
}

.hlsuTableData div {
  width: 20%;
  padding: 10px;
  font: normal 13px Montserrat;
  color:#7F99B5;
}