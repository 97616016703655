body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/images/background.png);
}

body.modal-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltipMain {
    padding: 10px;
    width: 280px;
    font: bold 16px Montserrat;
}

.tooltipHead {
	border-bottom: 1px solid #000;
	padding-bottom: 10px;
}

.tooltipCh { 
	display: flex;
	font: normal 15px Montserrat;
	padding-top: 10px;
}

.tooltipCh div { 
	padding-left: 0;
}

.tooltipCh div+div { 
	text-align: right;
}