.main {
  background: #fafbfc;
  padding: 20px 30px;
}

.flex {
  display: flex;
}

.bottomBrdr {
  border-bottom: 1px solid #f4f5f5;
}

.mt-5 {
  margin-top: 5px;
}

.titleText {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  font-family: Lato;
  letter-spacing: 0;
  color: #2d2d2d;
  opacity: 1;
}

.divider {
  height: 24px;
  border: 1px solid #b6cadd;
  opacity: 1;
  margin: 10px 15px 0;
}

.dividerB {
  height: 24px;
  border: 1px solid #2d2d2d;
  opacity: 1;
  margin: 10px 15px 0;
}

.downloadText {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  font-family: Lato;
  letter-spacing: 0;
  color: #30ad76;
  opacity: 1;
}

.dividerRP {
  height: 39px;
  border: 1px solid #dddddd;
  opacity: 1;
  margin: 17px 18px 17px 17px;
}

.ReanyaliseBtn {
  width: 152px;
  height: 50px;
  background: #f5fcfa 0% 0% no-repeat padding-box;
  border: 1px solid #009067;
  border-radius: 4px;
  opacity: 1;
  color: #009067;
  font: Bold 16px Raleway;
}

.ReanyaliseBtnDisable {
  width: 152px;
  height: 50px;
  background: #cccccc 0% 0% no-repeat padding-box;
  border: 1px solid #afacac;
  border-radius: 4px;
  opacity: 1;
  color: #fff;
  font: Bold 16px Raleway;
}

.loadMoreText {
  text-align: center;
  letter-spacing: 0;
  color: #828282;
  opacity: 1;
}

.loadMoreText:hover {
  cursor: pointer;
}

input {
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #0052cc;
}

.tag-title {
  margin-top: 3px;
}

.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #828282;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.tagKey-no-pointer {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #828282;
  border-radius: 50%;
  background: #fff;
  cursor: default;
}

.inprogressBtn {
  background: #fdf3da 0% 0% no-repeat padding-box;
  border: 1px solid #f2aa00;
  border-radius: 4px;
  opacity: 1;
  width: 145px;
  height: 39px;
  font: 16px Lato;
  font-weight: bold;
  color: #464141;
}

.failedBtn {
  background: #ffdede 0% 0% no-repeat padding-box;
  border: 1px solid #f74e4c;
  border-radius: 4px;
  opacity: 1;
  width: 145px;
  height: 39px;
  font: 16px Lato;
  font-weight: bold;
  color: #464141;
}
.completedBtn {
  background: #deffde 0% 0% no-repeat padding-box;
  border: 1px solid #33dd33;
  border-radius: 4px;
  opacity: 1;
  width: 145px;
  height: 39px;
  font: 16px Lato;
  font-weight: bold;
  color: #464141;
}

@media screen and (max-width: 767px) {
  .hideDiv {
    display: none;
  }

  .fixDivWidth {
    width: 200px !important;
  }

  .fixDivWidth200 {
    width: 50%;
  }

  .fixDivWidth185 {
    width: 185px;
  }

  .pl3em {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 1252px) {
  .fixDivWidth {
    width: 200px !important;
  }

  .pl3em {
    padding-left: 0px !important;
  }
}

.fixDivWidth {
  width: 180px;
}

.fixDivWidth200 {
  width: 200px;
}

.fixDivWidth185 {
  width: 185px;
}
.fixDivWidth500 {
  width: 500px;
}
.pl3em {
  padding-left: 3rem;
}

.width635 {
  width: 635px;
}

.bfNone:focus {
  outline: none;
}

.ulNone:hover {
  text-decoration: none;
}

.rptView {
  margin-top: 11px;
  border-radius: 4px;
  padding: 5px 20px 5px 5px;
  border: 1px solid #fff;
}

.rptView:hover {
  background-color: #fbfbfb;
  margin-top: 11px;
  border: 1px solid #eff2f3;
  border-radius: 4px;
  padding: 5px 20px 5px 5px;
}

.selectrptView {
  margin-top: 11px;
  border-radius: 4px;
  padding: 5px 20px 5px 5px;
  border: 1px solid #fbfbfb;
  background-color: #fbfbfb;
}

.css-yk16xz-control:hover {
  background-color: #fbfbfb !important;
}

.css-yk16xz-control {
  background-color: #fbfbfb !important;
}
