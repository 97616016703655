.kycTabs{
    .navLink{
        font-weight: normal;
        font-size: 13px;
        height: 45px;
        border-right: 2px solid rgba(166, 172, 190, 0.25);
        min-width:130px;
        background-color: #F8F8FA;
        color:#A6ACBE;  
        background: #F8F8FA;
        border: 1px solid #F1F2F3;
        box-sizing: border-box;
        box-shadow: 4px 0px 10px rgba(166, 172, 190, 0.12);
        align-items: center;
        display: flex;
        justify-content: center;
      
      }
      .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {        
        background-color: white;
      }
    
      .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        color: #404A58;
        background-color: white;
        border-color: #dee2e6 #dee2e6 #fff;
        border-left: 3px solid #55EDE4;        
    }
}


.kyc{
    height: 100%;
    background: #FFFFFF !important;
    border: 1px solid #d1d2d4;
    border-right:none;
    box-sizing: border-box;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: 0px 5.83033px 145.758px rgba(235, 238, 242, 0.05);
    .pHead {

        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 17.491px;
        line-height: 20px;
        letter-spacing: 0.08em;
        text-transform: capitalize;
        color: #394353;
        margin: 0px 5.83033px;
      }
    .card {
    
        border-radius: 0 !important;
    }
    .card_bg {
        border-radius: 4px;
        min-height: 60px;
    }
    // .card_bg p:first-child{
    
    // }
    .big_font{
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        color:#5F6D7A;
    }

    .upload {
        color: #2FA04F;
        font-weight: 500;
        font-size: 11px;
    }

    .upload_file{
        color: #8B9AA9;
        font-weight: bold;
        font-size: 10px;
        line-height: 10px;
    }
    .missing {
        color: #E06950;
        font-size: 11px;
        font-weight: 500;
    }
    .uploadFile {
        font-weight: 700;
        color: #8B9AA9;
        font-size: 12px;
    }
    .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
    }
    
    
    
    .upload-btn-wrapper input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }

   
}

.kycf {
    
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-right: 1px solid #d1d2d4;
}

.document-list {
    background-color:#FFFFFF;
    max-width: 671px;
    max-height: 309px;
    overflow-x: hidden;
    overflow-y: auto;
}

@media print{
    .document-list {
        background-color:#FFFFFF;
        max-width: none;
        max-height: none;
    }
}
