.summarySection{
    height: 100%;
    padding-bottom: 15px;
    background: #FFFFFF !important;
    border: 1px solid #d1d2d4;
    box-sizing: border-box;
    box-shadow: 0px 5.83033px 145.758px rgba(235, 238, 242, 0.05);
    .markFont{
        text-align: center;
        background-color: #FCFBE6;
        color:#959494;
        font-size: 13px;
        font-weight: 900;
    }
    .headerText{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 17.491px;
        line-height: 20px;       
        letter-spacing: 0.08em;
        text-transform: capitalize;
    }
    .Loadergif {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .dataFont{
        
        color:#666C72;
    }
    .cibilKey{
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #666C72;
        width: 60%;
        text-transform: capitalize;
    }
    .cibilValue{
        font-weight: 900;
        font-size: 15px;
        color: #666C72;
        text-align:right;
        width: 40%;
        text-transform: capitalize;
    }
    .buttonCss .btn-secondary {
        max-width: 225px !important;
    }
    .dropdown .bbankName.dropdown-toggle.btn.btn-secondary {
        max-width: 100% !important;
    }
    .bbankNameITem.dropdown-menu.show {
        max-width: 100% !important;
    }
    .bbankNameITem.dropdown-menu.show .dropdown-item {
        white-space: normal !important;
    }
    .buttonCss .dropdown-menu {
        position: absolute !important;
        top: 1% !important;
        max-width: 225px !important; 
        left: 0 !important;
    }
    .dropdown-menu {
        position: absolute !important;
        top: 1% !important;
        left: -36px !important;
    }
    .btn-secondary {
        color: #6c757d;
        background-color: white;
        border-color: #6c757d;
    }
    .btn-secondary:hover{
        color: #6c757d;
        background-color: white;
        border-color: #6c757d;
    }
    .btn-secondary.focus, .btn-secondary:focus {
        color: #6c757d ;
        background-color: white ;
        border-color: #6c757d;
        
    }
    .btn-secondary:active, .btn-secondary:active {
        color: #6c757d ;
        background-color: white ;
        border-color: #6c757d;
        
    }
    .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
        color: #6c757d ;
        background-color: white ;
        border-color: #6c757d;
    }
    .bd-highlight{
        margin-right: 10px;
    }
    .dropdown-item:focus, .dropdown-item:hover {
        color: #16181b;
        text-decoration: none;
        background-color: #c5c9ce8f;
    } 
    .legend-align{
        display: flex;
        align-items: flex-end;
        margin-right: 3px;
    } 
     
    .size_d{
        padding: 4px 7px;
        border-radius: 5px;
        box-shadow: 0px 0px 2px grey;
        font-size: 15px;
    } 
    .text_p{
        padding:3px;
        padding-left: 6px;
    }
}
.summarySection .btn-secondary {
    color: #16181b;
    background-color: white;
    border-color: #6c757d;
    padding: 0px 10px;
}

.gstGreen {
    color: white;
    background: #3FD68D;
    font-size: 13px;
    margin-right:7px;
    margin-left:20px;
    padding: 2px 5px;
    font-weight: normal;
}

.gstGreen1 {
    color: white;
    background: #3FD68D;
    padding: 3px 10px;
}

.gstGreen2 {
    color: #3FD68D;
    font-size: 13px;
    font-weight: normal;
}

.gstGreen3 {
    color: #3FD68D !important;
}