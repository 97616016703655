html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button:focus {
    outline: none;
}

/* =================================== 
    Main div And Header
===================================== */
.pl50 {
    padding: 0px 14% 0 14%;
    background: #9e9e9e0f;
}

.main-div {
    border: 1px solid #9e9e9e40;
}

header {
    padding: 35px 0 25px;
    padding-left: 50px;
    background: #fff;
    border-bottom: 1px solid #707070;
}

.heading p {
    font: Bold 22px/13px Lato;
    letter-spacing: 0px;
    color: #DD803B;
    padding-left: 11px;
    position: relative;
}

.heading p::after {
    content: '';
    width: 3px;
    height: 20px;
    left: 0;
    top: -3px;
    background: #DF8031;
    position: absolute;
}

/* =================================== 
    Main part content
===================================== */
.div-left {
    width: 81%;
    background: #fff;
    border-right: 1px solid #707070;
    /* padding-left: 80px; */
}

.div-left-content {
    background: #FFFBF8;
    padding-left: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.div-right {
    width: 19%;
    background: #FFF;
}

.version-blank {
    height: 89px;
    background: #fff;
}

.version1,
.version2 {
    font: Bold 22px/13px Lato;
    letter-spacing: 0px;
    color: #FFFDFD;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #707070;
    border: 1px solid #707070;
}

.version2 {
    color: #707070;
    background: #fff;
    border: 0;
    border-top: 1px solid #707070;
}

.title-content h5 {
    font: 14px/13px Lato;
    letter-spacing: 0px;
    color: #52667C;
    font-weight: 900;
}

.pt10 {
    padding-top: 15px;
}

.title-content h5:first-child {
    padding-right: 50px;
}

.pr50 {
    padding-right: 50px;
}

.gst-row {
    padding-top: 25px;
    padding-bottom: 10px;
}

.double-row {
    padding-bottom: 10px;
}

.gst-row p,
.double-row p {
    font: 14px/13px Lato;
    letter-spacing: 0px;
    color: #7F99B5;
    padding-bottom: 10px;
    font-weight: 400;
}

.gst-data input,
.double-row input {
    font: 10px/13px Lato;
    letter-spacing: 0px;
    border: 1px solid #EDE2E2;
    background: #fff;
    height: 17px;
    margin: 0 5px;
    padding: 0;
    max-width: 121px !important;
    text-align: center;
}

.gst-data input::placeholder,
.double-row input::placeholder {
    color: #000000;
    text-align: center;
}

.double-line {
    padding-left: 73px;
}

.text-cursor {
    cursor: auto;
}

/* =================================
      Button
===================================== */
.update-btn {
    height: 222px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.update-btn button {
    font: Bold 20px/13px Lato;
    height: 38px;
    letter-spacing: 0px;
    color: #FFFAFA;
    width: 167px;
    border: 0;
    background: #107920;
}

.update-btn label {
    text-align: left;
    font: Bold 20px/13px Lato;
    letter-spacing: 0px;
    color: #107920;
    opacity: 1;
}

.approve,
.reject {
    height: 222px;
    display: flex;
    align-items: center;
    margin-left: 28%;
    font: Bold 20px/13px Lato;
    letter-spacing: 0px;
    color: #107920;
    opacity: 1;
    justify-content: center;
}

.reject {
    color: #F34848;
}

/* =================================== 
    Main div And Header
===================================== */
.heading-id p {
    font: Bold 22px/13px Lato;
    letter-spacing: 0px;
    color: #DD803B;
    padding-left: 11px;
    position: relative;
}

.heading-id p::after {
    content: '';
    width: 3px;
    height: 20px;
    left: 0;
    top: -3px;
    background: #DF8031;
    position: absolute;
}

.heading-id h4 {
    font: Bold 22px/13px Lato;
    letter-spacing: 0px;
    padding-left: 18px;
    color: #707070;
}

.gst-number {
    border: 1px solid #707070;
    background: #FFFBF8;
}

.back-light {
    background: #FFFBF8 !important;
}

.gst-number p {
    font: Bold 22px/13px Lato;
    letter-spacing: 0px;
    color: #707070;
    text-align: center;
}

.gst-number-data {
    border-right: 1px solid #707070;
    height: 76px;
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: center;    
}

/* =================================== 
    Main part content
===================================== */
.main-div1 {
    background: #fff;
}

.main-data {
    width: 100%;
    background: #fff;
}

.div-data-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 90px;
}

.title-data h5 {
    font: 14px/13px Lato;
    letter-spacing: 0px;
    color: #52667C;
    font-weight: 900;
}

.pr50 {
    padding-right: 50px;
}

.next-line {
    padding-left: 78px;
}

.met-btn {
    margin-left: 1px;
}

.met-btn p,
.unmet-btn p {
    font: Bold 10px/13px Lato !important;
    letter-spacing: 0px;
    color: #FFFAFA !important;
    padding: 2px 24px;
    background: #107920;
}

.unmet-btn p {
    background: #EF0C0C;
}

.gst-row span,
.double-row span {
    font: Bold 20px/13px Lato;
    letter-spacing: 0px;
    color: #1118CE;
    padding: 0 3px;
}

.border-line {
    width: 11px;
    margin-left: 1px;
    height: 1px;
    background: #7F99B5;
}

.w-15 {
    width: 6%;
}

.w-58 {
    width: 70%;
}

.w-58a {
    width: 100%;
}

.w-l-1 {
    width: 10%;
}

.w-l-2 {
    width: 20%;
}

.pr-50 {
    padding-right: 50px;
}

.gst-data {
    flex-flow: row wrap;
}

/* =================================
      Button
===================================== */
.bottom-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
}

.bottom-btn .update-btn,
.bottom-btn .reject-btn {
    font: Bold 20px/13px Lato;
    height: 38px;
    letter-spacing: 0px;
    color: #FFFAFA;
    width: 167px;
    border: 0;
    margin: 0 50px;
    background: #107920;
}

.bottom-btn .reject-btn {
    color: #F34848;
    background: #fff;
    border: 3px solid #EF0C0C;
}

.reject-btn {
    color: #F34848;
}

/* =================================
      Responsive
===================================== */
@media screen and (max-width:1600px) {
    .w-58 {
        width: 70%;
    }
    .w-58a {
        width: 100%;
    }
}

@media screen and (max-width: 1400px) {
    .w-58 {
        width: 70%;
    }
    .w-58a {
        width: 100%;
    }
}

@media screen and (max-width:1200px) {
    .pl50 {
        padding: 0 9%;
    }

    .w-58 {
        width: 70%;
    }
    .w-58a {
        width: 100%;
    }
}

@media screen and (max-width:1050px) {
    .w-58 {
        width: 75%;
    }
    .w-58a {
        width: 100%;
    }
}

.main-div-gst {
    padding: 20px 40px;
    background: #FFF;
}

.tableGst {
    width: 100%;
    margin-top: 20px;
    text-align: center;
}

.tableHeader {
    display: flex;
}

.tableHeader .col-l-1 {
    width: 12%;
    padding-top: 7px;
    padding-bottom: 7px;
}

.tableBodyDiv {
    display: flex;    
}

.tableBodyDiv .col-l-1 {
    width: 12%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.tableHeader {
    color: #52667C;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background: #eeeeee;
    color: #333;
    font-weight: bold;
    font: 14px/13px Lato;
}

.tableBodyDiv {
    color: #333;
    border-bottom: 1px solid #dddddd;
    font: 14px/13px Lato;
}

.col-l-1 {
    border-right: 1px solid #dddddd;
}
.col-l-1a {
    border-left: 1px solid #dddddd;
}
.redBackrond {
    background: #EF0C0C;
    color: #fff;
}

.tableHeader .col-l-1 {
  height: 60px;
  line-height: 40px;
  text-align: center;
}

.tableHeader .col-l-1  span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font: 14px/13px Lato;
  color: #333;
}

.tableHeading {
    text-align: center;
    padding: 20px 0 5px;
    border-bottom: orange 1px solid;
    margin-bottom: 10px;
    color: orange;
    width: 25%;
    font-weight: bold;
    font-family: Lato;    
}

.tableGst:nth-of-type(odd) .tableHeading {
    border-color: #fb7435;
    color: #fb7435;
}
    
.tableGst:nth-of-type(even) .tableHeading {
     border-color: #224fb3;
     color: #224fb3;
}
.col-l-1 sup {
    font-size: 10px;
}

.extraBottom .col-l-1  {
    font-weight: bold;
}