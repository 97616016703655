.CAM-step {
  position: absolute !important;
  z-index: 9999;
  top: 509px;
  left: 43px;
  font: Bold 12px Montserrat;
  color: #4478CB;
  height: 110px !important;
  background-color: #fafafa !important;
  text-transform: capitalize;
}

.CAM-stepB {
  top: 789px;
}
.CAM-stepG {
  top: 1067px;
}

.CAM-stepPadding {
  padding: 70px 0px 0px 0px;
}
.CAM-StepL {
  top: 1207px;;
}

.rc-steps-item-title {
  font: bold 12px Montserrat !important;
}

.CAM-currentDot {
  width: 17px;
  height: 17px;
  transform: matrix(0, 1, -1, 0, 0, 0);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 5px solid #64778C;
  opacity: 1;
  border-radius: 8.5px;
}

.CAM-nextDot {
  width: 17px;
  height: 17px;
  transform: matrix(0, 1, -1, 0, 0, 0);
  background: #64778C 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 8.5px;
}

.CAM-inside-header {
  font: bold 13px Lato;
  letter-spacing: 0;
  color: #64778C;
  opacity: 1;
}

.rc-steps-item-container[role='button'] {
  outline: none;
}

.rc-steps-vertical.rc-steps-small .rc-steps-item-title {
  line-height: 16px;
}

.HeadingOrange {
  width: 500px;
}
.pointerc {
  cursor: pointer;
}
.rc-steps-item {
  min-height: 140px;
  height: auto !important;
}

.arrowClass {
  top: 472px;
  left: 279px;
  width: 23px;
  height: 12px;
  transform: matrix(0, 1, -1, 0, 0, 0);
  background: #4478CB 0% 0% no-repeat padding-box;
  opacity: 1;
}

.arrow11 {
  width: 23px;
  margin-left: 5px;
}

.line11 {
  margin-top: 9px;
  width: 15px;
  background: #4478CB;
  height: 2px;
  float: left;
}

.point11 {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 8px solid #4478CB;
    float: right;
    margin-top: 6px;
}

.currentInside {
  margin-top: 3px;
  cursor: pointer;
}

.currentInsideElse {
  color: #50657E;
  margin-top: 3px;
  opacity: 0.33;
  cursor: pointer;
}