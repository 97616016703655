.f1 {
  background-color: #FFFBF8;
}

.f2 {
  background: #FFFFFF;
}

.mainTitle {
  text-align: left;
  font: Bold 22px Montserrat;
  letter-spacing: 0;
  color: #DD803B;
  opacity: 1;
  padding-top: 10px;
  text-transform: capitalize;
}

.inputEditDiv {
  border: 1px solid #c4c4c4;
  width: 100%;
  outline: none;
}

.mainTitle.colorDark {
  color:#ca5703;
}

.mainTitle thead tr th {
  text-align: left;
  font: Black 14px Montserrat;
  letter-spacing: 0;
  color: #52667C;
  opacity: 1;
}

.f1 table tbody tr {
  background-color: transparent !important;
}

.f2 table tbody tr {
  background-color: transparent !important;
}

.f1 table thead tr {
  background-color: transparent !important;
}

.f2 table thead tr {
  background-color: transparent !important;
}

.f1 table thead tr th {
  background-color: transparent !important;
}

.f2 table thead tr th {
  background-color: transparent !important;
}

.mainTitle tbody tr td {
  text-align: left;
  font: Regular 13px Montserrat;
  letter-spacing: 0;
  color: #7F99B5;
  opacity: 1;
}

.tText {
  text-align: left;
  font: normal 14px/18px Montserrat;
  letter-spacing: 0;
  color: #3e4854;
  opacity: 1;
}

.tText img {
    margin-top: -1px;
    position: absolute;
}

.stText {
  margin: 20px 0 15px;
  text-align: left;
  font: Bold 13px Montserrat;
  letter-spacing: 0;
  color: #52667C;
  opacity: 1;
}

.stText + .stText {
  margin-top: 0px;
  margin-bottom: 20px;
}

.w20 {
  width: 20%;
}

.red {
  background: #F45151 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 3px;
}

.orange {
  background: #F79636 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 3px;
}

.green {
  background: #29AF58 0% 0% no-repeat padding-box;
  opacity: 1;
  cursor: default;
}

.mrgn {
  margin: 20px 100px;
}

.mrgn20 {
  margin: 20px 0px;
}

.padding40 {
  padding: 20px 30px 0px 30px
}

.tabBtn {
  background: #FDFEFD 0% 0% no-repeat padding-box;
  border: 1px dashed #96A3B276;
  border-radius: 2px;
  opacity: 1;
  margin: 20px 15px 20px 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 90%;
}

.tabBtndsbl {
  margin: 20px 15px 20px 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 90%;
}

.downloadBtn {
  width: 147px;
  height: 47px;
  background: #F59745 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  font: Bold 16px Montserrat;
}

.legendBtn {
  width: 137px;
  height: 47px;
  background: #7E87E8 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  border: none;
  font: normal 14px Montserrat;
}

.updateBtn {
  width: 161px;
  height: 47px;
  background: #009364 0% 0% no-repeat padding-box;
  border: none;
  opacity: 1;
  color: #fff;
  font: normal 14px Montserrat;
}

.updateBtn[disabled] {
  background: grey;
}

.profileImg {
  width: 44px;
  height: 44px;
  border-radius: 22px
}

.profileText {
  text-align: left;
  font: Bold 18px Montserrat;
  letter-spacing: 0.54px;
  color: #576E89;
  opacity: 1;
  margin-left: 10px;
}

.padding10 {
  padding: 10px;
}

.profileBody {
  background-color: #F9F9FB;
}

.inputText {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  color: #7F99B5;
  opacity: 1;
}

.rc-steps-item-tail {
  height: 40px;
  background-color: #64778C;
  left: 7.5px !important;
  padding: 22px 0 4px 0 !important;
  border: 1px solid #64778C;
}

.rc-steps-item-tail:after {
  content: '';
  display: inline-block;
  background: #64778C !important;
  height: 40px !important;
  width: 100%;
}

.rc-steps-item-container {
  height: 75px;
}

.rc-steps-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  flex: 1 1;
  overflow: hidden;
  height: 140px;
}

.rc-steps-item-custom.rc-steps-item-process .rc-steps-item-icon>.rc-steps-icon {
  color: transparent;
}

.popupBox {
  width: 464px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 13px 6px #c4b8b808;
  border: 1px solid #E5E9ED;
  opacity: 1;
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 18%;
}

.popupBox.arrow-top:after {
  content: " ";
  position: absolute;
  right: 40px;
  top: -13px;
  border-top: none;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 12px solid #FAFAFC;
}

.leftArrow {
  top: -16px;
  right: 58px;
  position: absolute;
  height: 18px;
  width: 1px;
  background-color: #E5E9ED;
  transform: rotate(46deg);
}

.rightArrow {
  top: -16px;
  right: 45px;
  position: absolute;
  height: 18px;
  width: 1px;
  background-color: #E5E9ED;
  transform: rotate(136deg);
}

.borderLine {
  border-bottom: 1px solid #EDEDED;
}

.sidePanelText {
  text-align: left;
  font: Bold 17px Montserrat;
  letter-spacing: 0;
  color: #52667C;
  opacity: 1;
  padding-top: 4px;
}

.popupText {
  text-align: left;
  font: Bold 14px Montserrat;
  letter-spacing: 0;
  color: #52667C;
  opacity: 1;
  padding-left: 10px;
}

.popupTitle {
  text-align: left;
  font: Bold 17px Montserrat;
  letter-spacing: 0;
  color: #52667C;
  opacity: 1;
}

.mt_2 {
  margin-top: -2px;
}

.mt_4 {
  margin-top: -4px;
}

.border {
  border-bottom: 0.5px solid #EDEDED;
  opacity: 1;
}

.borderTop {
  border-bottom: 0.5px solid #EDEDED;
  opacity: 1;
}

.borderBottom {
  border-bottom: 0.5px solid #EDEDED;
  opacity: 1;
}

.borderRight {
  border-right: 0.5px solid #EDEDED;
  opacity: 1;
}

.borderLeft {
  border-left: 0.5px solid #EDEDED;
  border-right: 0.5px solid #EDEDED;
  opacity: 1;
}

.AddtoCartText {
  text-align: left;
  font: Bold 18px Montserrat;
  letter-spacing: 0;
  color: #52667C;
  opacity: 1;
}

.menuTxt {
  text-align: left;
  font: Bold 24px Montserrat;
  letter-spacing: 0;
  color: #5D6F83;
  opacity: 1;
}

.h35 {
  height: 35px;
}

@media only screen and (max-width: 991px) {
  .tabBtn {
    background: #FDFEFD 0% 0% no-repeat padding-box;
    border: 1px dashed #96A3B276;
    border-radius: 2px;
    opacity: 1;
    margin: 20px 15px 20px 38px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 180px;
  }

  .tabBtndsbl {
    margin: 20px 15px 20px 38px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 180px;
  }

  .AddtoCartText {
    text-align: left;
    font: Bold 18px Montserrat;
    letter-spacing: 0;
    color: #52667C;
    opacity: 1;
    margin-left: 25px;
  }
}

.view-cam-summary-header {
  height: 64px;
  opacity: 1;
  border-top: 0.5px solid #DFE8F0;
  font: Bold 16px Montserrat;
  align-items: center;
}

.view-cam-summary-header-text {
  font: bold 16px Montserrat;
  color: #65778B;
  opacity: 1;
}

/* .view-cam-summary-mAuto {
  margin: auto;
}

.view-cam-summary-flex {
  display: flex;
} */
.headingGraph1 {
  float:left;
  font: bold 16px Montserrat;
  color: #65778B;
}

.headingGraph2 {
  float:right;
  margin-right: 100px;
  font: bold 14px Montserrat;
  color: #65778B;
}
.partScore {
  padding-left: 0;
}

.partScore+.partScore {
  border-left: 1px solid #EDEDED;
  padding-left: .5em;
}

.react-confirm-alert-body  {
   font: normal 14px Montserrat !important;
}

.react-confirm-alert-button-group > button {
    background: #DD803B !important;
    border-radius: 0 !important;
}

.react-confirm-alert-body > h1 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}