.loan-input {
  background: #FDFEFD 0% 0% no-repeat padding-box;
  border: 1px solid #E8EBEF;
  border-radius: 2px;
  opacity: 1;
  width: 308px;
  height: 39px;
}

.loan-content-text {
  text-align: left;
  font: Bold 14px Montserrat;
  letter-spacing: 0;
  color: #65778B;
  opacity: 1;
}

.loan-leftBorder {
  border-left: 1px solid #DFE8F1;
}
.loan-leftBorderBtm {
  border-bottom: 1px solid #DFE8F1;
}
.loan-brdr {
  border-top: 1px solid #DFE8F1;
}

.borderBottom707070 {
  border-bottom: 1px solid #DFE8F1;
}

.loan-input-text {
  background: #FDFEFD 0% 0% no-repeat padding-box;
  border: 1px solid #96A3B2;
  border-radius: 2px;
  opacity: 0.44;
  width: 308px;
  height: 39px;
}
.width20{
  width:20%
}
.width30{
  width:30%
}
.width25{
  width:25%
}
.widthTemp1 {
  width:100%;
}
.widthTemp2 {
  width:50%;
}
.widthTemp3 {
  width:33%;
}
.widthTemp4 {
  width:25%;
}
.widthTemp5 {
  width:20%;
}
.widthTemp6 {
  width:16%;
}