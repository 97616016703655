.LoanEligible{
  .navLink{
    font-weight: normal;
    font-size: 13px;
    padding: 15px 0 !important;
    border-right: 2px solid rgba(166, 172, 190, 0.25);
    width:130px;
    color: #50575E;    
    background: rgba(166, 172, 190, 0.08);
    letter-spacing: 0.387828px;

    
  }
  .pHead {

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 17.491px;
    line-height: 20px;
    letter-spacing: 0.08em;
    text-transform: capitalize;
    color: #394353;
    margin: 0px 5.83033px;
  }
  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    
    background-color: white;
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: white;
    border-color: #dee2e6 #dee2e6 #fff;
    border-bottom: 3px solid #55EDE4;
    
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    color: #6B788E;
    }
    .pointer{
      cursor : pointer;
    }
    
    td, th {
      border: 1px solid #dddddd;
      text-align: right;
      padding: 15px;
      line-height: 18px;
      text-transform: capitalize;
    }
    th{
      font-size: 18px;
      border: none;
      background-color: #F3F6FB;
    }
    
    tr:nth-child(even) {
      background-color: #ffffff;
    }
    tr:nth-child(odd) {
      background-color: #ffffff;
    }

    .tablehead{
      text-align: left !important;
      width: 60%;
      text-transform: capitalize;
    }

    .reportDwnld {
      float: right;
      display: flex;
      align-items: center;
      padding-top: 20px;
      font-weight: 600;
      font-size: 15.89px;
      color: #6B788E;
      cursor: pointer;
    }
    .bankAmountDiv{
      display: flex;
      background: #FAFAFA;
      border-radius: 16px;
      justify-content: space-between;
      height: 70px;
      padding: 0 15px;
      align-items: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14.7459px;
      line-height: 17px;
      margin: 15px 0;
      color: #818181;
    }
    .amountDiv{
      background: #DEFEEE;
      border-radius: 10.0227px;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 37.25px;
      font-size: 16.7545px;
      line-height: 12px;
      color: #4e9471;
      width: auto;
      padding: 0px 10px;
    }
    .mainBankDiv{
      background-color: #fff;
      padding: 10px 20px;
    }
  }