.mintifiScore {

    .scoreCard{
        height: 100%;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 10px;
        letter-spacing: 0.01em;
       
    }
    .scoreCard span {
        display: block;
        padding: 10px 5px;
        margin: 10px 0;
        min-height: 30px;
    }

    .bgclr {
        background-color: #FFF;
       
    }
    .bgclr2 {
        background-color:  #F6F8FB;
    }
    .green {
        background-color: #3ED68D;
    }
    .amber{
        background-color: #FF9E57;
    }
    .red {
        background-color: #EE5D5D;
    }
    .card{
        background-color: white;
        }
    .bg{
            background-color: #F0F0F0;
        }
    .p1{
        color:#7B7E7E;
        font-size: 16px;   
        padding-bottom: 10px;     
    }
    .p2{
        color:#6F6F6F;
        font-size: 22.9245px;
        line-height: 27px;
    }


}