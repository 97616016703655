.cibil{
    .co_applicant_table{
        td:nth-child(2){
            font-size: 14px;
            line-height: 20px;
        }
    }
    .residence_address{
        border: 1px solid #dddddd;;
        background-color: #ffffff;
        padding: 15px 8px;
        color: #6B788E;
    }
    
}
