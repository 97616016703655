.table-dark-card {
  border-left: 5px solid white;
  border-right: 5px solid white;
  line-height: 45px;
}

.table-dark-card>img {
  float: right;
}

.table-light-card {
  border-left: 5px solid white;
  border-right: 5px solid white;
  line-height: 45px;
}

tr:nth-child(odd) {
  background-color: #ECEBEB;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.table-light-card>img {
  float: right;
}

.table th {
  background-color: #fff;
}

.table thead th {
  border-bottom: 0px solid #dee2e6;
}

.table td,
.table th {
  border-top: 0px solid #dee2e6;
}

.flex {
  display: flex
}

.cp {
  cursor: pointer;
}

.cpDefault {
  cursor: default !important;
}

.Input-div {
  background: #FDFEFD 0% 0% no-repeat padding-box;
  border: 1px solid #96A3B2;
  border-radius: 2px;
  color: #65778B;
  font: Bold 14px Montserrat;
  opacity: 1;
  padding: 10px;
  min-height: 40px;
  word-wrap: break-word;
}

.tabPrint {
  display: none !important;
}

@media print{
  .loanCibilDisplay{
    display: none;
  }
  .printNotShow{
    display: none !important;
  }
  .small_score_font{
    font-weight: 500;
    font-size: 14px;
    line-height: 44px;
  }
  .newCam{
    margin: 0px !important;
  }
  
  .print-col{
    display: contents;
  }
  .print-margin{
    margin-top:15px;
    margin-left: 15px;
  }
  .btn {
    visibility: hidden;
  }
  .pagebreak { page-break-before: always; margin-top: 10px; clear: both;}

  .print-row{
    display: flex;
    flex-wrap: nowrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .printScreenTab .tab-pane  {
    display: contents !important;
    text-align: left;
    color: #404A58;
    background-color: white;
    border-color: #dee2e6 #dee2e6 #fff;
  }

  .printScreenTab.nav.nav-tabs {
    display: none !important;
  }
  .tabPrint {
    display: contents !important;
  }
  .tabPrintAttach {
    display: none !important;
  }

  .printMintifi{
    margin-top: 25px;
  }
}