.Originationrisk {
    .mainScrollDivInnerList { float: left; width: 100%; }
    .heightEq { height: 60px; }
    .heightEq3 { height: 121px; }
    .heightEq20 { height: 20px; }
    
    .or_Head_3 {
        font-family: Roboto;
        font-style: normal;
        font-size: 17px;
        font-weight: 500;
    	text-transform: capitalize;
    	border-bottom: 1px solid #ddd;
    	padding-top: 20px;
    	background: #FAFAFA;
    	color: #414A58;
    }
    .gutterLeft {
    	padding-left: 0;
    }
    .d-flex.gutterBoth {
        border-top: 1px solid #ddd;
    }
    .gutterBoth {
    	padding-left: 0;
    	padding-right: 0;
        text-align: right;
    }

    .valueDiv1 {
        font-family: Roboto;
        font-style: normal;
        font-size: 17px;
        font-weight: 500;
    	padding-right: 30px;
    	padding-top: 20px;
    	//border: 1px solid #ddd;
        background: #FAFAFA;
        color: #414A58;
    }

    .valueDiv {
        font-family: Roboto;
    	font-size: 16px;
        font-style: normal;
        color: #6B788E;
    	padding-right: 30px;
    	padding-top: 20px;
    	border-bottom: 1px solid #ddd;

    }
    .valueDivHead {
        font-family: Roboto;
    	font-size: 17px;
        font-style: normal;
        color: #000;
    	padding-right: 30px;
    	padding-top: 20px;
    	border-bottom: 1px solid #ddd;
        background-color: #FAFAFA;
    }

    .valueDivLabel {
    	font-family: Roboto;
    	font-size: 16px;
        font-style: normal;
        color: #6B788E;
    	padding-right: 30px;
    	padding-top: 20px;
    	border-bottom: 1px solid #ddd;     
    }

    .or_Head_1 {
        font-family: Roboto;
        font-style: normal;
        font-size: 17px;
        font-weight: 500;
        color: #414A58;
        border: 1px solid #ddd;
        background-color: #FAFAFA;
        display: flex;
        align-items: center;
        padding-left: 20px;
    	
    }
    .mainScrollDiv {
    	width: 100%;
        overflow-x: auto;
    }

    .topborderDiv1 {
    	border-bottom: 1px solid #ddd;
        padding-right: 29px;
    }

    .contentColorDiv {
    	background-color: #fff;
    }
   
}