.itr{
  
    .tablereturn {
        border: 1.91841px solid #D8DAE5;
        th {
            padding-left: 40px;
            text-align: left !important;
            border: none;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 17.2657px;
            line-height: 15px;
            /* or 89% */
        }

        td {
            padding-left: 40px;
            text-align: left !important;
            border: none;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 13.4288px;
            line-height: 15px;
        }

        tr:nth-child(even) {
            background-color: #F7F8FA;
        }


    }
    
    .colorx {
        display: inline;
        letter-spacing: 0;
        color: #1118ce;
        padding: 0 3px;
        font-size: 18px;
    }
    .navLink{
        font-weight: normal;
        font-size: 15.3473px;
        height: 45px;
        border-right: 2px solid rgba(166, 172, 190, 0.25);
        min-width:130px;
        background-color: rgba(166, 172, 190, 0.08);

        border: 1px solid #e9ecef;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        border-radius: 15.3473px 15.3473px 0px 0px;
        box-shadow: none;
    
        
      }
      .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
        
        background-color: white;
      }
    
      .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        color: #495057;
        background-color: white;
        border-color: #dee2e6 #dee2e6 #fff;
        font-weight: bold;
        
      }

    .confirmbtn .btn-secondary{
        width: 70%;
        background: #F0F4FB;
    border: 0.778277px solid #C9D6ED;
    color: #8192AF;
    
    }
    .loanIDbtn {
        border: none;
        background: #FFFDF2;
        border: 0.466966px solid #E6E2C8;
        color: #9E9C89;
        padding: 10px 20px;
        text-align: center;
        display: inline-block;
        font-size: 16px;
    }
   
    .overflow-hidden {
        overflow: hidden;
    }
    .active{
        color:#5cad8a;
    }
    .deactivate{
        color:rgb(153, 53, 53);
    }
    .dropdown-menu{
      width: 13%;
      margin-left: 0%;
    } 
   
    .dropdown-toggle {
        white-space: nowrap;
        color: #00CEB4;
        background: #FFFFFF;
        border: 0.933932px solid #C9D6ED;
    }
}
.itrdetails{
    .navLink{
      font-weight: normal;
      font-size: 13px;
      padding: 15px 0 !important;
      border-right: 2px solid rgba(166, 172, 190, 0.25);
      width:130px;
      color: #50575E;    
      background: rgba(166, 172, 190, 0.08);
      letter-spacing: 0.387828px;
  
      
    }
    .pHead {
  
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 17.491px;
      line-height: 20px;
      letter-spacing: 0.08em;
      text-transform: capitalize;
      color: #394353;
      margin: 0px 5.83033px;
    }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      
      background-color: white;
    }
  
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
      color: #495057;
      background-color: white;
      border-color: #dee2e6 #dee2e6 #fff;
      border-bottom: 3px solid #55EDE4;
      
    }
  
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
      font-weight: 700;
      font-size: 16px;
      color: #6B788E;
      }
      .pointer{
        cursor : pointer;
      }
      td{
        line-height: 40px; 
      }
      td, th {
        border: 1px solid #dddddd;
        text-align: right;
        padding: 15px;      
        
      }
      th{
        font-size: 18px;
        border: none;
        background-color: #F3F6FB;
      }
      
      tr:nth-child(even) {
        background-color: #ffffff;
      }
      tr:nth-child(odd) {
        background-color: #ffffff;
      }
  
      .tablehead{
        text-align: left !important;
        width: 60%;
      }
  
      .reportDwnld {
        float: right;
        display: flex;
        align-items: center;
        padding-top: 20px;
        font-weight: 600;
        font-size: 15.89px;
        color: #6B788E;
        cursor: pointer;
      }
      .bankAmountDiv{
        display: flex;
        background: #FAFAFA;
        border-radius: 16px;
        justify-content: space-between;
        height: 70px;
        padding: 0 15px;
        align-items: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14.7459px;
        line-height: 17px;
        margin: 15px 0;
        color: #818181;
      }
      .amountDiv{
        background: #DEFEEE;
        border-radius: 10.0227px;
        width: 116px;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 37.25px;
        font-size: 16.7545px;
        line-height: 12px;
        color: #7CC5A0;
      }
      .mainBankDiv{
        background-color: #fff;
        padding: 10px 20px;
      }
      .increasing
      {
        color: #45DD70;
        font-size: 9px;
      }
      .sub_text{
          text-align: right;
          display: inline-block;
      }
      .normal
      {
        font-size: 9px;
        color: #6B788E;
      }
    }
 