.Cam-flex {
  display: flex;
}

.textFont {
  font: Bold 14px Montserrat;
  color: #65778B
}

.textFontMain {
    font: Bold 16px Montserrat;
    color: #65778B
}

.Cam-Summary-header {
  height: 70px;
  background: #4F647F;
  opacity: 1;
  font: Bold 16px Montserrat;
}

.Cam-Summary-header-text {
  color: #ffffff;
  font: Bold 16px Montserrat;
  opacity: 1;
}

.Cam-Summary-content-text {
  border-left: 0.5px solid #DFE8F0;
  font: Bold 14px Montserrat;
  color: #65778B;
  text-align: left;
  opacity: 1;
}

.Cam-flex.borderTopDFE8F0 {
  border-top: 0.5px solid #DFE8F0;
}

.Cam-Summary-content-main-text {
  border: 0.5px solid #DFE8F0;
  font: Bold 14px Montserrat;
  color: #3A424B;
  text-align: left;
  opacity: 1;
}

.Cam-Summary-top {
  border-top: 1px solid #DFE8F0;
  opacity: 1;
}

.Cam-Summary-input {
  background: #FDFEFD 0% 0% no-repeat padding-box;
  border: 1px solid #96A3B2;
  border-radius: 2px;
  height: 39px;
  color: #65778B;
  font: Bold 14px Montserrat;
  opacity: 1;
}

.Cam-Summary-inside-input {
  background: #FDFEFD 0% 0% no-repeat padding-box;
  border: 1px solid #96A3B2;
  border-radius: 2px;
  opacity: 0.44;
  height: 39px;
}

.Cam-Summary-Met {
  background: #F5FDFA 0% 0% no-repeat padding-box;
  border: 1px solid #009166;
  border-radius: 2px;
  opacity: 1;
  width: 110px;
  height: 39px;
}

.Cam-Summary-Met-Center {
  text-align: center;
  font: Bold 14px Montserrat;
  color: #009265;
  opacity: 1;
}

.Cam-Summary-UnMet {
  background: #FDF5F5 0% 0% no-repeat padding-box;
  border: 1px solid #F07575;
  border-radius: 2px;
  opacity: 1;
  width: 110px;
  height: 39px;
}

.Cam-Summary-UnMet-Center {
  text-align: center;
  font: Bold 14px Montserrat;
  letter-spacing: 0;
  color: #F37472;
  opacity: 1;
}

.Cam-Summary-NA {
  background: #FCFDFC 0% 0% no-repeat padding-box;
  border: 1px solid #E7EAED;
  border-radius: 2px;
  opacity: 1;
  height: 39px;
}

.Cam-Summary-deviation {
  display: none;
}

.Cam-Summary-show-deviation+.Cam-Summary-deviation {
  display: block;
}

.Cam-Summary-popupBox {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  z-index: 1;
  right: -90px;
  top: 10px;
  width: 230px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
  border-radius: 5px;
}

.Cam-Summary-popupBox.Cam-Summary-arrow-top:after {
  content: "";
  position: absolute;
  right: 49px;
  top: -13px;
  border-top: none;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 12px solid #FAFAFC;
}

.Cam-Summary-left-content-text {
  font: Bold 14px Montserrat;
  letter-spacing: 0;
  color: #65778B;
  opacity: 1;
}

.Cam-Summary-middle-background {
  background: #FCFCFC 0% 0% no-repeat padding-box;
  opacity: 1;
}

.Cam-Summary-comment-button {
  background: #F5FDFA 0% 0% no-repeat padding-box;
  border: 1px solid #009166;
  border-radius: 2px;
  opacity: 1;
  margin-right: 10px
}

.Cam-Summary-comment-text {
  text-align: center;
  font: Bold 14px Montserrat;
  letter-spacing: 0;
  color: #009265;
  opacity: 1;
  padding: 5px;
}

.Cam-Summary-cancel-text {
  text-align: center;
  font: Bold 14px Montserrat;
  letter-spacing: 0;
  color: #536A8D;
  opacity: 1;
  padding: 5px;
}

.Cam-Summary-cancel-button {
  background: #F9F9FD 0% 0% no-repeat padding-box;
  border: 1px solid #546B8C;
  border-radius: 2px;
  opacity: 1;
}
.commentBox {
  position: absolute;
  z-index: 9999;
  right: -10px;
  top: 50px;
}
.posRelative {
  position: relative;
}
.cam-inpuText{
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  color: #90979F;
  opacity: 1;
  background-color: transparent !important;
  border-bottom: 1px solid #E1E5E8;
  padding:10px 0px
}
.commentAt{
  font-size: 11px;
  font-family: Montserrat;
  color:#9AA0A7;
  padding-top: 3px;
}
.topRightCaret {
  content: " ";
  position: absolute;
  right: -7px;
  top: -3px;
  border-top: none;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 12px solid #F4BC27ed;
  transform: rotate(45deg);
}

.cam-bgColor {
  background-color: #FFFAE8;
}

.borderleft707070 {
  border-left: 1px solid #e8e5e5;
}

.labelrightText {
  text-align: left;
  font: normal 14px/29px Montserrat;
  letter-spacing: 0px;
  color: #68829F;
}

.bordertop707070 {
  border-top: 1px solid #e8e5e5;
}

.bordertopBottom707070 {
  border-top: 1px solid #e8e5e5;
  border-bottom: 1px solid #e8e5e5;
}