.co-applicant-header {
  opacity: 1;
  border-top: 0.5px solid #DFE8F0;
  font: Bold 16px Montserrat;
}

.co-applicant-header-text {
  font: Bold 16px Montserrat;
  color: #65778B;
  opacity: 1;
}

.co-applicant-text {
  text-align: left;
  font: Bold 14px Montserrat;
  letter-spacing: 0;
  color: #65778B;
  opacity: 1;
}

.co-applicant-input-text {
  background: #FDFEFD 0% 0% no-repeat padding-box;
  border: 1px solid #96A3B2;
  border-radius: 2px;
  opacity: 0.44;
  height: 39px;
}

.co-applicant-flex {
  display: flex
}

.co-applicant-main-header {
  height: 80px;
  text-align: center;
  font-size: 36px;
  padding-top: 12px;
  color: #666;
}