  @font-face {
    font-family: Roboto-Regular;
    src: url(../../assets/fonts/Roboto-Regular.ttf);
  }
  @font-face {
    font-family: Roboto;
    src: url(../../assets/fonts/Roboto-Medium.ttf);  
  }
  @font-face {
    font-family: Roboto-Light;
    src: url(../../assets/fonts/Roboto-Light.ttf);
  }
  @font-face {
    font-family: Roboto-Bold;
    src: url(../../assets/fonts/Roboto-Bold.ttf);
  }
  @font-face {
    font-family: Helvetica-Bold;
    src: url(../../assets/fonts/Helvetica-Bold.ttf);
  }
  @font-face {
    font-family: Helvetica-Bold;
    src: url(../../assets/fonts/Helvetica-Bold.ttf);
  }
  @font-face {
    font-family: Helvetica-Regular;
    src: url(../../assets/fonts/Helvetica.ttf);
  }

body {
  background: #d1d2d4 !important;
  background-image: none;
}


.newCam {
  min-height: 1000px;
  font-family: Roboto;
  font-weight: normal;
  font-style:normal;
  letter-spacing: 0.01em;
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  padding: 15px 23px;
  background: #F9F9F9;
  border: 1px solid #D1D1D1;
  box-sizing: border-box;
  box-shadow: 0px 4px 60px rgba(209, 212, 224, 0.82);
  border-radius: 16px;
  margin: 30px 40px;
  
  .has-search .form-control {
    padding-left: 4.5rem;
    font-size: 18px;
  }
  
  .has-search .form-control-feedback {
    position: absolute;
    z-index: 1;
    display: block;
    width: 3.375rem;
    height: 2.6rem;
    line-height: 4.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    padding-top: 12px;
    padding-left: 11px;
    
  }
  .search-size{
    width:100%;
  }
  .searchInput{
    border-radius: 30px;
    height:55px;
  }
  .logo-position{
    padding-right: 50px;
  }
  
}  

.circleProgress{
  padding-top:10px;
  padding-bottom: 10px;
}

@media (min-width: 481px) and (max-width: 767px) {
    
  /* CSS */
  .newCam{
    padding: 18px 0px;
  }
  .logo-position{
    padding-right: 20px;
  }
  
}