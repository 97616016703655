.myinput[type="checkbox"]:before {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  content: "";
  background: #FFF;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-image: url('/images/unchecked.png');
  margin-left: -15px;
  margin-top: 5px;
  background-size: 32px;
}

.myinput[type="checkbox"]:after {
  position: relative;
  display: block;
  margin-left: -15px;
  top: -37px;
  margin-top: 5px;
  width: 32px;
  height: 32px;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px;

}

.myinput[type="checkbox"]:checked:after {
  background-image: url('/images/unchecked.png');
}

.myinput[type="checkbox"]:not(:disabled):checked:after {
  background-image: url('/images/checked.png');
}

.fileIcon {
  padding-right: 20px;
  padding-left: 20px;
}

@media screen and (max-width: 1250px) {
  .bcFlex {
    display: flow-root !important;
  }

  .iconWidth {
    max-width: 100% !important;
  }
}


@media screen and (max-width: 768px) {
  .iconWidth {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333% !important;
  }

  .bcFlex {
    display: flex !important;
  }
}

@media screen and (max-width: 621px) {
  .iconWidth {
    max-width: 100% !important;
  }

  .bcFlex {
    display: flow-root !important;
  }
}

.bcFlex {
  display: flex;
}