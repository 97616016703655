.bg-light {
  background: #F6F8FC !important;
}
.proposal {

  .baddress {
    min-height:80px;
  }
  
  .proposal_card{
    background: #FFFFFF !important;
    border: 1px solid #d1d2d4;
    box-sizing: border-box;
    box-shadow: 0px 5.83033px 145.758px rgba(235, 238, 242, 0.05);
    padding: 10px 15px;
    min-height: 100%;
  }
  .pHead {

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 17.491px;
    line-height: 20px;
    letter-spacing: 0.08em;
    text-transform: capitalize;
    color: #394353;
    margin: 0px 5.83033px;
  }
  .userBox {
    background: rgba(245, 248, 252, 0.77);
    border-radius: 5.83033px;
    height: 60px;
    align-items: center;
    padding: 10px 20px;
  }

  .alogo {
    width: 50px;
  }

  .anchorBox {
    background: #F8FDF5;
    border-radius: 5.83033px;
    height: 60px;
    height: 60px;
    align-items: center;
    padding: 10px 15px;
  }

  .card-text{
    background-color: #FEFFD2;
    padding: 5px 10px;
    border-radius: 5.83033px;

  }
  .img_position{
    position: absolute;
    bottom: 22px;
    left: 31px;

  }
  .coapp:last-child {
    padding-right: .75rem!important;
  }
  .space1 {
    margin-top: 13px;
    height: 62px;
  }
  .boldFontMain{
    font-size: 11px;
    font-weight: bold;
    background-color: #E8FAF1;
    color:#40C284;
    
  }
  .boldFont{
    font-size: 12px;
    // font-weight: 500;
    line-height: 15px;
    color:#4F565E;
  }
  .smallFont{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: 0.01em;
    color: #A6ACBE;
  }
  .cNamebox {
    background: #F6F8FC;
    border-radius: 2.91516px;
    height: 45px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    padding: 0 10px;
  }
  .vintagebox {
     height: 45px;
     border-radius: 2.91516px;
     display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    padding: 0 10px;
    justify-content: space-between;
  }
  .vintageText{
    font-weight: bold;
    font-size: 23.3213px;   
    border-radius: 2.91516px;
  }
  .vintageYr{
    font-weight: bold;
    font-size: 8px;
  }
  .applicantText{
    font-weight: 500;
    font-size: 12px;
    color: #A6ACBE;
  }
  .applicantName1{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #3ED68D;
  }
  .applicantName2{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #4F565E;
  }
  .businessBorder{
    border-right: 1px solid #DEE3ED;
    ;
  }
  .userFont{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14.5758px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: rgba(54, 62, 70, 0.87);
  }
  .anchorImgLogo{
    width: 35px;
    height: 25px;
    color: #A6ACBE;
    border: 1px solid #A6ACBE;
    text-align: center;
    margin-right: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    border-radius: 45px;
    justify-content: center;
    font-weight: 400;
    padding-top: 1px;
  }  
  .posBox {
    background: #F6F8FC;
    border-radius: 5.83033px;
    align-items: center;
    padding: 10px 10px 10px 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    letter-spacing: 0.01em;
    color: #A6ACBE;
    height: 100%;
  }
  .perform{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    letter-spacing: 0.01em;
    color: #A6ACBE;
  }
  .performanceBox {
    background: #F6F8FC;
    border-radius: 5.83033px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .leftPerformance{
    display: flex;
    padding: 10px 10px 0 10px;
    justify-content: space-between;
    border-right: 0.437275px solid #DEE3ED;
  }
  .rightPerformance{
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  .leftPos {
    font-family: Roboto;
    font-style: normal;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    font-weight: bold;
    color: #37ABFE;
  }
  .rightPos {
    font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 12px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.01em;
color: #0148BE;
  }
  .program-text{
    font-size: 12px;
    padding-right: 16px;
    color: #828491;
    text-align: left;
  }

  @media (min-width: 1281px) {
  
    /* CSS */
    .col-md-2-0 {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
      }
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    .col-md-2-0 {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
      }

      .vintageText{
        font-size: 13.3213px;

      }
      .p-3 {
        padding: .5rem!important;
    }
    .boldFont {
      font-size: 9px;
    }
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    .vintageText{
      font-size: 13.3213px;
    }
    .p-3 {
      padding: .5rem!important;
    }
    .boldFont {
      font-size: 9px;
    }
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    .p-2 {
      padding: .2rem!important;
  }
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    /* CSS */
    
  }
}

