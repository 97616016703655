.imgStyle {
  width: 44px;
  height: 44px;
  opacity: 1;
  background-color: #ddd;
  border-radius: 100px
}

.box {
  height: 80px;
  width: 80px;
  margin: auto !important;
  padding: 25px 25px;
  border-left: 0.5px solid #E7ECF2;
}

.topHeader {
  height: 80px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 24px #25265E05;
  border: 1px solid #F0F3F4;
  opacity: 1;
}

.menuName {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  font-family: Montserrat;
  letter-spacing: 0;
  color: #5C6377;
  opacity: 1;
}