.finalOffering{
    .loanofferP {
      padding-left: .5em; 
        padding-right: .5em; 
    }
    .card{
        background-color: white;
        }
        
    .p1{
        color:#7B7E7E;
        font-size: 15px;   
        padding-bottom: 10px;   
        height: 35px;  
    }
    .p2{
        color:#6F6F6F;
        font-size: 18px;
        line-height: 27px;
    }
    .offerBox{
    border-radius: 16px;
    height: 100px;
    }
    
        
    
}
.finalRemark{
    .card{
        background-color: #F3F6FB;;
        }
    .col{
        background-color:white; 
    } 
    .inputEditText{
      border: 1px solid #c4c4c4;
      width: 100%;
      outline: none;
      resize: none;
      padding: 7px;
      line-height: 22px;
  }  
  .inputEditDiv{
    width: 90%;
    border: none;
  }
  .editBtn{
    width: 10%;
  } 
}


@media (min-width: 1281px) {
  
    /* CSS */
    .finalOffering .col-md-2-0 {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
      }
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    .finalOffering .col-md-2-0 {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
      }
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    /* CSS */
    
  }