html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

* {
    text-decoration: none;
}

a {
    text-decoration: none !important;
}

/* ==========================================
    main-div
============================================= */
.main-div {
    padding-left: 80px;
}

/* ==========================================
    header
============================================= */
header {
    height: 80px;
}

/* ==========================================
    borrow business detail
============================================= */
.borrow-detail {
    padding-right: 40px;
    padding-left: 50px;
    padding-bottom: 50px;
    background: #bfb6de21;
}

.borrow-business {
    padding: 20px 0;
    border-bottom: 1px solid #E7ECF2;
}

.detail-heading p {
    font: Bold 33px/30px Lato;
    letter-spacing: 0px;
    color: #405255;
}

.minitif-score p {
    font: Bold 20px/30px Lato;
    letter-spacing: 0px;
    color: #40a0ad;
    margin-right: 20px !important;
}

.minitif-score a {
    font: Bold 29px/30px Lato;
    letter-spacing: 0px;
    background: #24a0ad;
    color: #fff;
    border-radius: 50px;
    padding: 12px 30px;
}

.minitif-score a:hover {
    color: #fff;
}

/* ==========================================
    yatra type
============================================= */
.business-menu {
    padding: 30px 0 45px;
}

.yatra-type {
    padding: 0 25px;
}

.yatra-type:first-child {
    padding-left: 0px;
}

.yatra-type label {
    font: 18px/14px Lato;
    color: #ababbb;
    font-weight: 400;
}

.yatra-type p {
    font: bold 18px/14px Lato;
    padding-left: 18px;
    color: #00000080;
    line-height: 1.4;
    max-width: 170px;
}

/* ==========================================
    chart body
============================================= */
.chart-body {
    background: #fff;
    height: 80%;
}

.y-axis {
    font: 13px lato;
    color: #59595d;
}

.bar--positive {
    fill: #0000ffad;
}

.bar--negative {
    fill: #ff0000a6;
}

.toolTip {
    position: absolute;
    display: none;
    min-width: 40px;
    font-family: lato;
    height: 20px;
    background: none repeat scroll 0 0 #ffffff;
    border: 1px solid #0000006b;
    padding: 0px 20px;
    text-align: center;
}