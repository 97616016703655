.deviation{
    
    td, th {
        text-align:left;
       }
    .card{
    background-color: #F3F6FB;
    }
    
    Table tr > .mainTd,th{
        background-color: #FAFAFA;
        color:#414A58;
        font-size: 16px;
        line-height: 19px;
        padding-left:25px;
        padding:18px 30px 18px 30px;       
       
    }
    table td{
        border-top: 1px solid #dee2e6;
        font-size: 15px;
        line-height: 18px;
        background-color: white;
        padding-left: 40px;
        color:#6B788E;
        padding:18px 30px 18px 30px;
        
    }
    th{
        border:none;
    }
    .overflow-hidden {
        overflow: hidden;
    }
    tbody tr td{
        border:1px solid #DEE6F4;
    }
    .link {
        color: rgb(19, 19, 186) !important;
        cursor: pointer;
        text-decoration: underline !important
      }
      
      .link:hover {
        color: rgb(205, 21, 21) !important;
        text-decoration: underline;
      }
      .borBack{
        border: 1px solid #DEE6F4;
      }
    
}
.commentModel{
    font-family: Roboto;
}
