.proposal-input-text {
  background: #FDFEFD 0% 0% no-repeat padding-box;
  border: 1px solid #96A3B2;
  border-radius: 2px;
  opacity: 0.44;
  width: 308px;
  height: 39px;
}

.proposal-text-main {
  text-align: left;
  font: Bold 16px Montserrat;
  letter-spacing: 0;
  color: #65778B;
  opacity: 1;
  padding-left: 30px;
}

.proposal-text {
  text-align: left;
  font: Bold 14px Montserrat;
  letter-spacing: 0;
  color: #65778B;
  opacity: 1;
}

.proposal-flex {
  display: flex
}

.borderPTop707070 {
  border-top: 1px solid #e8e5e5;
}

.borderPBottom707070 {
  border-bottom: 1px solid #e8e5e5;
}