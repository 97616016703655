.creditManager{
    .btnExit {
        width: 30px;
        height: 30px;
        display:flex;
        justify-content: center;
        margin-left: 10px;
    }
    .card{
    background-color: #F3F6FB;
    }
    td, th {
     text-align:left;
    }
    tr th:first-child {
        width: 30%;
    }
    Table tr > td:first-child,th{
        background-color: #FAFAFA;
        color:#414A58;
        font-size: 18px;
        padding-left:25px;
        padding:18px 30px 18px 30px;       
       
    }
    Table tr > td:nth-child(2){
        line-height: 22px;
        word-break: break-all;
        // display: flex;
        align-items: center;
        justify-content: space-between;
    }
    Table tr > td:nth-child(3){
        line-height: 22px;
        word-break: break-all;
        // display: flex;
        align-items: center;
        justify-content: space-between;
    }
    table td{
        border-top: 1px solid #dee2e6;
        font-size: 15px;
        background-color: white;
        padding-left: 40px;
        color:#6B788E;
        padding:18px 30px 18px 30px;
        
    }
    .overflow-hidden {
        overflow: hidden;
    }
    .inputEditDiv{
        border: 1px solid #c4c4c4;
        width: 100%;
        outline: none;
        resize: none;
        padding: 7px;
    }

    .jodit-wysiwyg {
        word-break: keep-all !important;
    }
}