.mintifiLEC{
    .mintifilecC {
        padding-left: .5em; 
        padding-right: .5em; 
    }
    .card{
        background-color: white;
        }
        .bg{
            background-color: #F3F6FB;
        }
    .p1{
        color:#7B7E7E;
        font-size: 16px;   
        padding-bottom: 10px;     
    }
    .p2{
        color:#6F6F6F;
        font-size: 22.9245px;
        line-height: 27px;
    }
}