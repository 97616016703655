input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

* {
  text-decoration: none;
}

section {
  display: inline-block !important;
  vertical-align: top;
  width: 100%;
}

input:focus,
select:focus {
  outline: none;
}

.pl80 {
  padding-left: 46px;
}

.pl80 .logo {
  display: inline-block;
  position: absolute;
  left: 0;
}

input[type="radio"] {
  display: none;
}

/* simulate radiobutton appearance using pseudoselector */

input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  padding: 5px;
  margin-right: 5px;
  background-clip: content-box;
  border: 1px solid #24a0ad;
  border-radius: 50%;
}

/* appearance of checked radiobutton */
input[type="radio"]:checked + label:before {
  background-color: #24a0ad;
}

input[type="heckbox"] + label:before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  padding: 5px;
  margin-right: 5px;
  background-clip: content-box;
  border: 1px solid #24a0ad;
  border-radius: 50%;
}

input[type="checkbox"]:checked + label:before {
  background-color: #24a0ad;
}

.pl70 {
  padding-left: 70px;
}

/*=================================
   header
===================================*/
.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pl80 .logo img {
  width: 43px;
  height: 43px;
}

.header .menu li img {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  vertical-align: middle;
}

.header .menu ul {
  float: right;
}

.header li:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #5c6377;
  padding: 15px 60px 15px 0;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.header li:first-child:after {
  content: "\f107";
  position: absolute;
  right: 25px;
  font-family: "fontawesome";
}

.header li {
  display: inline-block;
  padding: 15px 15px;
  border-right: 1px solid #dddddd;
}

.header li i {
  color: #7c849b;
  font-size: 21px;
}

.header li a {
  font-size: 14px;
  color: #ff5733;
}

.header li a:hover {
  color: #000;
  border-bottom: 1px solid #000;
}

/*=================================
   banking
===================================*/
.banking {
  padding: 10px 15px 10px;
}

.banking .id ul li:nth-child(2) {
  border-right: 1px solid #dddddd;
}

.banking .id ul i {
  background: #e8f5f1;
  color: #008e64;
  width: 40px;
  height: 40px;
  padding: 13px;
  border-radius: 5px;
  font-size: 15px;
  text-align: center;
}

.banking .id ul li {
  padding: 0 10px;
}

.banking .id ul li:last-child {
  position: relative;
}

.banking .id ul li:last-child:after {
  content: "\f104 ";
  position: absolute;
  left: 0;
  top: 0;
  font-family: "fontawesome";
}

.banking .id ul li:last-child:before {
  content: "\f105";
  position: absolute;
  right: 0;
  font-family: "fontawesome";
}

.banking .score {
  /* border-top: 1px solid #dddddd; */
  border-bottom: 1px solid #dddddd;
}

.banking .score ul li {
  display: inline-block;
  padding: 0 35px;
  position: relative;
}

.banking .score ul li a {
  color: #000;
  text-align: left;
  font: Bold 20px/40px Lato;
  text-decoration: none;
}

.type-selected {
  content: "";
  position: absolute;
  height: 2px;
  background-color: #748293;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  transition: 0.5s;
}

.report .bnk-rpt {
  color: #02444e;
  font: Bold 22px/33px Lato;
}

.report .dt-year {
  font: Bold 20px/36px Lato;
  color: #828282;
  margin: 0;
}

.report .proges-btn {
  font: Bold 18px Lato;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.report .report-square {
  border-color: #ffffff;
  /* padding: 15px; */
}

.ErrtoolTip .tooltip .tooltip-inner {
  background: #f74f4c;
}
.ErrtoolTip .tooltip .arrow {
  border: none;
}
.ErrtoolTip .tooltip .arrow::before {
  border-right-color: #f74f4c;
}

.report .date ul li .border {
  font-size: 18px;
  font-weight: bold;
  color: #896e3a;
  background-color: #fdf3da;
  padding-right: 45px !important;
}

.report .date ul li a {
  text-decoration: none;
}

.pt15 {
  padding: 35px;
}

.report .date ul li .pr-bar:after {
  content: "\f110";
  right: 10px;
  top: 7px;
  color: #896e3a;
  position: absolute;
  font-family: "fontawesome";
}

.report .date ul li:last-child a {
  font-size: 20px;
  font-weight: bold;
  color: #828282;
  font-family: "Lato", sans-serif;
  padding-right: 35px !important;
}

.report .date ul li .dt-year:after {
  content: "\f073";
  position: absolute;
  color: #24a0ac;
  right: -34px;
  top: 2px;
  font-family: "fontawesome";
}

.report .no-report .no-avail {
  font: Bold 18px Lato;
  color: #24a0ad;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  border-right: 1px solid #dee2e6 !important;
  height: 39px;
}

.report .no-report .no-avail div {
  padding-right: 10px;
}

.report .no-report .no-avail span {
  font: Bold 20px Lato;
  color: #000;
  padding-right: 8px;
  border-right: 1px solid #dee2e6;
  text-align: center;
}

.report .no-report .view,
.report .no-report .download {
  text-align: left;
  letter-spacing: 0px;
  color: #838b8b;
  font: Bold 20px/77px Lato;
  position: relative;
}

/*=================================
   Load More
===================================*/
.load-more h5 {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #828282;
}

.load-more h5:after {
  content: "\f107";
  position: absolute;
  bottom: -35px;
  font-size: 28px;
  left: 37px;
  color: #009068;
  font-family: "fontawesome";
}

/*=================================
   bank statement
===================================*/

.bank-stmnt .container {
  border: 1px solid #e7ecf2;
  padding: 40px 40px;
}

.bank-stmnt .upload-data {
  /* border: 1px dashed #468675; */
  border-radius: 4px;
}

.bank-stmnt .upload-data .stmnt-head p {
  color: #405255;

  font: Bold 22px/33px Lato;
}

.bank-stmnt .upload-data .upload ul li {
  border-left: 1px solid #92d1c081;
}

.bank-stmnt .upload-data .upload ul {
  background-color: #e7f7f0;
}

.bank-stmnt .upload-data .upload ul li i {
  color: #30ad76;
  font-size: 25px;
  padding: 21px;
  text-align: center;
}

.bank-stmnt .upload-data .upload ul li p {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #30ad76;
  margin: 0;
  padding: 13px;
}

.pl75 {
  padding-left: 75px;
}

.stmnt-data {
  /* padding: 0 30px; */
}

.bank-stmnt {
}

.bank-stmnt .stmnt-023 .pdf:after {
  content: "";
  background-image: url("/images/Group 2193.png");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  color: #24a0ac;
  width: 36px;
  top: 18px;
  left: 29px;
  font-size: 30px;
  height: 36px;
}

.bank-stmnt .stmnt-023 .pdf .st-023 {
  color: #717684;
  font: Bold 16px/33px Lato;
}

.bank-stmnt .stmnt-023 .pdf .date {
  color: #24a0ac;
  font: Bold 13px/10px Lato;
  opacity: 0.7;
}
.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  font: Bold 16px/20px Lato;
  color: #6d7383;
}
.bank-stmnt .stmnt-023 .pwd-data ul .trash i {
  background-color: #ffebeb;
  width: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 51px;
  font-size: 21px;
  color: #ed6060;
  text-align: center;
  border-radius: 50px;
}

.bank-stmnt .stmnt-023 .pwd-data ul .true i {
  width: 50px;
  height: 50px;
  display: flex;
  font-size: 25px;
  color: #24a0ad;
  justify-content: center;
  align-items: center;
}

.bank-stmnt .stmnt-023 .pwd-data ul li:first-child div input {
  border: 1px solid #24a0ad;
  text-align: left;
  height: 28px;
  padding: 0 0 0 32px;
}

.bank-stmnt .stmnt-023 .pwd-data ul li:first-child div .lock {
  position: relative;
}

.bank-stmnt .stmnt-023 .pwd-data ul li:first-child div .lock:after {
  position: absolute;
  content: "\f023";
  color: #24a0ad;
  left: 0;
  border-right: 1px solid #24a0ad;
  width: 26px;
  text-align: center;
  font-family: "fontawesome";
  font-size: 20px;
  height: 27px;
}

.bank-stmnt .invite-btn button {
  background: #156c74 0% 0% no-repeat padding-box;
  color: #fff;
  width: 150px;
  height: 40px;
  border: 3px;
  border-radius: 3px;
  border: 0;
  text-align: left;
  padding: 0 0 0 15px;
  font: Bold 14px Raleway;
  position: relative;
}

.bank-stmnt .invite-btn button:after {
  content: "";
  background-image: url("/images/Group 2187.png");
  background-repeat: no-repeat;
  position: absolute;
  font-family: "fontawesome";
  right: 17px;
  top: 5px;
  color: #4bc18d;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.bank-stmnt .invite-btn button:before {
  content: "";
  position: absolute;
  width: 2px;
  right: 62px;
  top: 0;
  height: 40px;
  background-color: #145f65;
}

.bank-stmnt .app-div .loan-id .app-id label,
.bank-stmnt .app-div .loan-id .date-range label,
.bank-stmnt .app-div .loan-id .last-div div label,
.bank-stmnt .app-div .loan-id .from-date label,
.bank-stmnt .app-div .loan-id .limit-type label,
.day-redction label {
  font: Bold 16px/20px Lato;
  color: #6d7383;
}

.bank-stmnt .app-div .loan-id {
  padding: 30px 30px 0;
}

.bank-stmnt .app-div .loan-id .from-date label {
  opacity: 0;
}
.date input {
  padding-left: 0;
}
.bank-stmnt .app-div .loan-id .app-id label span,
.bank-stmnt .app-div .loan-id .date-range label span,
.bank-stmnt .app-div .loan-id .last-div .last-div1 label span,
.bank-stmnt .app-div .loan-id .last-div .last-div2 label span,
.limit-01 label span {
  color: #db2a2a;
}

.pl-68 {
  padding-left: 68px;
}

.plr45 {
  padding: 0 15px 10px;
}

.icon {
  position: relative;
}

/* .bg-color {
  background-color: #fcfcfc !important;
} */

/* input {
    color: #B4BAC3 !important;
} */

.bank-stmnt .app-div .loan-id .app-id input,
.bank-stmnt .app-div .loan-id .limit-type input,
.bank-stmnt .app-div .loan-id .last-div div input,
.bank-stmnt .app-div .loan-id .app-id select,
.day-redction select {
  border: 0;
  height: 29px;
  margin: 0 38px 0 0;
  color: rgb(109, 115, 131);
  font: Bold 16px/14px Lato;
  border-bottom: 1px solid #24a0ad;
}

.bank-stmnt .app-div .loan-id .app-id input {
  margin: 0 32px 0 0;
}

.bank-stmnt .app-div .loan-id .app-id .bnk:after {
  position: absolute;
  content: "\f107";
  right: 5px;
  color: #7c849b;
  cursor: pointer;
  font-size: 25px;
  font-family: fontawesome;
}

.bank-stmnt .app-div .loan-id #bank-div,
.bank-stmnt .app-div .loan-id .facility #bank-div {
  padding: 21px 0 33px 25px;
}

.bank-stmnt .app-div .loan-id .app-id .bnk select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

.pl30 {
  padding-left: 0px !important;
}

.SingleDatePickerInput__withBorder {
  border: 0;
}

.DateInput {
  width: 100%;
}

.cal {
  justify-content: center;
  margin: 17px 0 0 0;
  align-items: center;
  text-align: center;
  width: 10%;
}

.pr15 {
  padding-right: 15px;
}

.pl15 {
  padding-left: 15px;
  width: 45%;
}
.arrow {
  margin-left: 20px;
}
.arrow,
.arrow1 {
  justify-content: center;
  flex-flow: column;
  display: flex;
  border: 2px solid #2aa0ad;
  border-radius: 50px;
  width: 35px;
  height: 35px;
}
.arrow1 {
  width: 36px;
  height: 36px;
}
.bank-stmnt .app-div .loan-id .date .cal i {
  color: #24a0ad;
}

.DateInput_input {
}
input.position-relative.m-0.bg-color::placeholder {
  opacity: 0.4;
}

.dark-color::placeholder {
  color: #575e71 !important;
}

[placeholder="date"] {
  color: #b4bac3;
}

.date.dateRangeCol .DateErrror {
  padding-left: 25px;
}

.toggle {
  margin-bottom: 51px !important;
}

/* .day-redction{
    margin-top: -51px;
} */
.bank-stmnt .app-div .loan-id .date .date-range input,
.bank-stmnt .app-div .loan-id .date .from-date input {
  border: 0;
  border-bottom: 1px solid #24a0ad;
  font: Bold 24px/14px Lato;
  background-color: #fafbfc;
  position: relative;
  color: #b4bac3;
  width: 112%;
}

.bank-stmnt .app-div .loan-id .date .date-range input:after {
  content: "\f073";
  position: absolute;
  right: 0;
  top: 0;
  font-family: "fontawesome";
}

.bank-stmnt .app-div .loan-id .facility {
  background-color: #fff;
  border-radius: 4px;
  display: inline-block;

  width: 100%;
}

.bank-stmnt .app-div .loan-id .company-nm {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
}

.bank-stmnt .app-div .loan-id .facility {
  background-color: #fff;
  vertical-align: bottom;
}

.bank-stmnt .app-div .loan-id .limit-01 {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  /* border: 1px solid #a7c4c7; */
}

.limit-type {
  padding-left: 30px;
}

.ptb {
  padding-top: 35px !important;
  padding-bottom: 50px !important;
}

.bank-stmnt .app-div .loan-id .last-div .last-div1 .radio-btn {
  position: absolute;
  display: flex;
  align-items: center;
  left: 40%;
  top: -4px;
}

.bank-stmnt .app-div .loan-id .last-div .last-div1 .radio-btn input {
  width: 25px;
  height: 25px;
}

.bank-stmnt .app-div .loan-id .last-div .last-div1 .radio-btn input:checked {
  background-color: #2420ad;
}

.bank-stmnt .app-div .loan-id .last-div .last-div1 .radio-btn label {
  font-size: 24px;
  color: #575e71;
  padding-right: 20px;
  padding-left: 10px;
  font-family: "Lato", sans-serif;
}

.bank-stmnt .app-div .loan-id .limit-01 div .value,
.drawing .limit-type span {
  display: inline-block;
  position: absolute;
  bottom: 36px;
  z-index: 1;
  width: 56px;
  border: 1px solid #24a0ad;
  background: #f6fbfc;
  height: 52px;
  color: #24a0ad;
  font-size: 24px;
  font-family: "Lato", sans-serif;
  text-align: center;
}

.red-color {
  color: #db2a2a;
}

/* input#date3 {
  padding: 0;
  height: 35px;
}
input#date4 {
  padding: 0;
  height: 35px;
} */
.drawing h5 {
  font: Bold 16px/20px Lato;
  color: #6d7383;
}
input.text-left.pl70.position-relative::placeholder {
  opacity: 0.4;
}

/* .bank-stmnt .app-div .loan-id .facility div {
    padding-right: 39px !important;
} */

.bank-stmnt .app-div .loan-id .company-nm .app-id {
  padding-left: 30px;
}

.bank-stmnt .app-div .loan-id .company-nm .app-id textarea {
  margin: 0 39px 0 0;
  border-color: #24a0ad;
  font-size: 13px;
  color: #24a0ad;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
}

.submit-btn {
  margin: 15px 0;
  display: inline-block;
}

.submit-btn button {
  float: right;
  padding: 13px 0 15px 15px;

  background: #156c74 0% 0% no-repeat padding-box;
  color: #fff;
  width: 120px;
  height: 40px;
  border: 3px;
  border-radius: 3px;
  border: 0;
  text-align: left;

  font: Bold 14px Raleway;
  position: relative;
}

.submit-btn button:after {
  content: "";
  background-image: url("/images/Path 3147.png");
  background-repeat: no-repeat;
  /* right: 18px; */
  text-align: center;
  position: absolute;
  /* width: 30px;
  top: 15px;
  height: 30px; */

  /* content: "";
  background-image: url("/images/Group 2187.png"); */

  font-family: "fontawesome";
  right: 0px;
  top: 15px;
  color: #4bc18d;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.dr-power {
  padding: 20px 0 20px 0 !important;
}

.dr-amount {
  padding: 25px 0 !important;
}

.bank-stmnt .app-div .loan-id .last-div .last-div1 label,
.bank-stmnt .app-div .loan-id .last-div .last-div2 label {
  margin: 0 0 30px;
}

.last-div {
  display: inline-block;
  padding: 0 15px;
}

.last-div3 {
  display: inline-block !important;
  width: 100%;
}

.radio-button {
  position: absolute;
  display: inline-block;
  right: 0;
}

.input-block {
  display: block;
  width: 100%;
}

.pr39 {
  padding-right: 39px !important;
}

.pls-img {
  position: absolute;
  right: 59px;
  top: 44px;
}

.pd35 {
  padding: 35px !important;
}

.date-range {
  width: 45%;
  padding: 0 15px;
}

#textarea p {
  font-size: 13px;
  border-radius: 52px;
  padding: 5px;
  margin: 0;
  position: relative;
  text-align: left;
  color: #2aa0ad;
  width: auto;
  border: 1px solid #2aa0ad;
}

.area-contain a {
  padding-left: 10px;
}

.area-contain {
  display: inline-block;
  width: 100%;
}

.data {
  position: relative;
  float: left;
  padding: 5px 0 0 10px;
}

.from-date {
  width: 45%;
  padding: 0 15px;
  margin-top: 20px;
}

.stmnt-023 {
  background-color: #fff;
}
.css-1377y3x-ValueContainer {
  font: Bold 16px/20px Lato;
  color: #6d7383 !important;
}
.css-b4fv4k-singleValue {
  color: #6d7383 !important;
}
#textarea {
  -webkit-appearance: textarea;
  border-radius: 5px;
  font: medium -moz-fixed;
  border-bottom: 1px solid #2aa0adab;
  border-left: 1px solid #2aa0adab;
  border-right: 1px solid #2aa0adab;
  font: -webkit-small-control;
  height: 44px;
  overflow: auto;
  padding: 2px;
  resize: none;
  width: 100% !important;
}

.SingleDatePickerInput {
  width: 100% !important;
}
.SingleDatePicker_picker {
  z-index: 3;
  left: -20px !important;
  top: 55px !important;
  box-shadow: 3px 3px 5px 2px #ccc;
}

.DateInput_fangStroke {
  fill: #27a699;
}
.DateInput_fang {
  top: 40px !important;
}
.drawing {
  margin: 0;
}

.css-1uccc91-singleValue {
  font: Bold 24px Lato;
  color: #575e71;
}

.cal-date {
  padding: 0 24px 0 0;
  margin: 0;
  display: flex;
}

.last-div3 .over-click {
  margin-top: 17px;
  display: block;
}

.report-square .date,
.no-report {
  /* width: 50%; */
}

.pr39 {
  padding-right: 50px !important;
}

.text-pointer {
  cursor: pointer;
}

.BankDiv {
  width: 200px;
  display: inline-block;
  margin-left: 10px;
  margin-top: 15px;
}

.bank-stmnt .app-div .loan-id .date .date-range input,
.bank-stmnt .app-div .loan-id .date .from-date input {
  height: 34px;
  padding-left: 0;
}

.AppDivBank {
  width: 100% !important;
  width: 100% !important;
  border: 1px solid #dee2e6 !important;
  margin: 50px 0 30px 0;
  border-radius: 4px;
}

.LastDivApp {
  border: 1px solid rgb(167 196 199);
  border-radius: 4px;
}

.submit-btn button:before {
  content: "";
  position: absolute;
  width: 2px;
  right: 40px;
  top: 0;
  height: 40px;
  background-color: #145f65;
}

/*====================================
	responsive
======================================*/
@media screen and (max-width: 1600px) {
  .last-div {
    width: 50% !important;
  }
  .radio-button {
    right: 0;
  }
}
@media screen and (max-width: 1500px) {
  /* #textarea{
        max-width: 93%;
    } */
  .bank-stmnt .app-div .loan-id .app-id input {
    margin: 0 37px 0 0;
  }
}

@media screen and (max-width: 1480px) {
  .bank-stmnt .app-div .loan-id .app-id input {
    margin: 0 41px 0 0;
  }
}

@media screen and (max-width: 1345px) {
  .bank-stmnt .app-div .loan-id .app-id input {
    margin: 0 37px 0 0;
  }
}

@media screen and (max-width: 1284px) {
  /* .radio-button {
        right: 10px;
    } */

  .bank-stmnt .app-div .loan-id .app-id input {
    margin: 0 33px 0 0;
  }
}

@media screen and (max-width: 1230px) {
  .report-square .date,
  .no-report {
    width: 100%;
  }

  .no-report .report-data {
    float: left !important;
  }
}

@media screen and (max-width: 1180px) {
  .bank-stmnt .app-div .loan-id .app-id input {
    margin: 0 31px 0 0;
  }
}
@media screen and (max-width: 1200px) {
  .radio-btn {
    right: 10px;
  }
}
@media screen and (max-width: 1175px) {
  .date-range {
    width: 43%;
    padding: 0 15px;
  }

  .cal {
    width: 14%;
  }

  .from-date {
    width: 42%;
  }
}

@media screen and (max-width: 1140px) {
  .bank-stmnt .app-div .loan-id .app-id input {
    margin: 0 27px 0 0;
  }
}

@media screen and (max-width: 1080px) {
  .product-type {
    margin-bottom: 25px !important;
  }
}

@media screen and (max-width: 992px) {
  .bank-stmnt .app-div .loan-id .app-id input {
    margin: 0 29px 0 0;
  }
}
@media screen and (max-width: 991px) {
  .last-div {
    width: 100% !important;
  }
  .radio-button {
    right: 45%;
  }
}
@media screen and (max-width: 929px) {
  .bank-stmnt .app-div .loan-id .app-id input {
    margin: 0 26px 0 0;
  }
}

@media screen and (max-width: 870px) {
  .report .report-square .no-report ul {
    width: 100%;
    display: inline-block !important;
  }
}

@media screen and (max-width: 870px) {
  .report .no-report .no-avail {
    float: left;
    display: inline-block;
  }

  .inblock,
  .report .no-report .view {
    display: inline-block !important;
  }
}

@media screen and (max-width: 768px) {
  .report .report-square .date ul {
    width: 100%;
    display: inline-block !important;
  }

  .fixDivWidth185 {
    float: left;
  }

  .radio-button {
    right: 69px;
  }

  .fixDivWidth185,
  .cal-date {
    width: 200px;
    /* margin-top: 5px; */
  }

  .bank-stmnt .app-div .loan-id .app-id input,
  .bank-stmnt .app-div .loan-id .limit-type input,
  .bank-stmnt .app-div .loan-id .last-div div input,
  .bank-stmnt .app-div .loan-id .company-nm .app-id textarea {
    margin: 0;
  }

  /* .pls-img {
        right: 4px;
    } */

  .bank-stmnt .app-div .loan-id .app-id,
  .bank-stmnt .app-div .loan-id .date,
  .bank-stmnt .app-div .loan-id .company-nm .app-id {
    padding: 0;
  }

  .bank-stmnt .app-div .loan-id .last-div .last-div1 {
    padding-bottom: 10px !important;
  }

  .bank-stmnt .app-div .loan-id .company-nm {
    padding: 25px 30px;
  }

  .bank-stmnt .app-div .loan-id .app-id select {
    margin: 0 15px 0 0;
  }

  .bank-stmnt .app-div .loan-id #bank-div {
    padding-left: 0;
  }

  .pd35 {
    padding: 10px !important;
  }

  /* #textarea {
        max-width: 100%;
    } */

  .drawing {
    width: 100%;
    margin: 0;
  }

  .bank-stmnt .app-div .loan-id {
    padding-top: 50px;
  }
}

.ulNone {
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-right: 1px solid #dee2e6 !important;
}

@media screen and (max-width: 734px) {
  .ulNone {
    display: inline-block !important;
  }
}

@media screen and (max-width: 658px) {
  .mb10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 578px) {
  .report .no-report .no-avail {
    display: block;
  }

  .pl75 {
    padding-left: 50px;
  }

  .bank-stmnt .stmnt-023 .pdf:after {
    left: 7px;
  }
}

@media screen and (max-width: 425px) {
  .banking {
    padding: 20px 0;
  }

  .banking .id ul li:first-child {
    padding-left: 0;
  }

  .banking .report .report-square .date ul li {
    padding: 0 !important;
    padding-bottom: 10px !important;
  }

  .banking .report .report-square .date,
  .banking .report .report-square .no-report {
    padding: 0 !important;
  }

  .banking .report .no-report .view,
  .banking .report .no-report .download {
    padding: 5px 0 !important;
  }

  .banking .report .date ul li .dt-year:after {
    right: 6px;
  }

  .header li:first-child {
    padding: 15px 0 15px 0;
  }

  .bank-stmnt .app-div .loan-id .last-div .last-div1 .radio-btn {
    position: inherit;
    left: 0;
    top: 0;
  }

  .bank-stmnt .app-div .loan-id .date div,
  .bank-stmnt .app-div .loan-id .date .cal {
    width: 100%;
    padding: 0;
  }
}
