.cpv-table-header {
  background-image: url('/images/codes-04.png');
  background-size: 100% 100%;
  color: white;
  text-align: center;
  border: 5px solid white;
}

.cpv-table-body>tr>th {
  text-align: center;
}

.cpv-table-image-header>th {
  text-align: center;
}

.report-name {
  top: 30px;
  position: relative;
  width: 300px;
  color: #EF5723;
  opacity: 1;
}

.report-hr {
  position: relative;
  top: 25px;
}

.width31 {
  width: 31%
}

.width23 {
  width: 23%
}