.gst{
    
    .tablereturn {
        border: 1.91841px solid #D8DAE5;
        th {
            padding-left: 40px;
            text-align: left !important;
            border: none;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 15px;
            /* or 89% */
        }

        td {
            padding-left: 40px;
            text-align: left !important;
            border: none;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            line-height: 15px;
        }

        tr:nth-child(even) {
            background-color: #F7F8FA;
        }


    }
    
    .colorx {
        display: inline;
        letter-spacing: 0;
        color: #1118ce;
        padding: 0 3px;
        font-size: 18px;
    }
    .navLink{
        font-weight: normal;
        font-size: 15.3473px;
        height: 45px;
        border-right: 2px solid rgba(166, 172, 190, 0.25);
        min-width:130px;
        background-color: rgba(166, 172, 190, 0.08);

        border: 1px solid #e9ecef;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        border-radius: 15.3473px 15.3473px 0px 0px;
        box-shadow: none;
    
        
      }
      .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
        
        background-color: white;
      }
    
      .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        color: #495057;
        background-color: white;
        border-color: #dee2e6 #dee2e6 #fff;
        font-weight: bold;
        
      }

    .confirmbtn .btn-secondary{
        width: 70%;
        background: #F0F4FB;
    border: 0.778277px solid #C9D6ED;
    color: #8192AF;
    
    }
    .loanIDbtn {
        border: none;
        background: #FFFDF2;
        border: 0.466966px solid #E6E2C8;
        color: #9E9C89;
        padding: 10px 20px;
        text-align: center;
        display: inline-block;
        font-size: 16px;
    }
   
    .overflow-hidden {
        overflow: hidden;
    }
    .active{
        color:#5cad8a;
    }
    .deactivate{
        color:rgb(153, 53, 53);
    }
    .dropdown-menu{
        width: 70%;
    } 
   
    .dropdown-toggle {
        white-space: nowrap;
        width: 70%;
        color: #00CEB4;
        background: #FFFFFF;
        border: 0.933932px solid #C9D6ED;
    }
}
.gstdetails{
   table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        color: #666C72;
    }
    tr:nth-child(even) {
        background-color: #F7F8FA;
    }
    tr:nth-child(odd) {
        background-color: #fff;
    }
     td {
        border: none;
        text-align: left;
        padding: 15px;
        width: 50%;
        line-height: 22px;
    }
    .bottomtable td {
        width: 25%;
    }
    .bottomtable tr:nth-child(even) {
        background-color: #F7F8FA;
    }
    .bottomtable tr:nth-child(odd) {
        background-color: #F7F8FA;
    }
    .bottomtable p {
        padding: 15px;
    }
    .overflow-hidden {
        overflow: hidden;
    }
    .gstin{
        font-weight: 700;
        font-size: 16px;
        color: #666C72;
        background-color: white;
    }
}
.gstRule{
    .overflow-hidden {
        overflow: hidden;
    } 
}