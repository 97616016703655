.mainScrollDivInnerList { float: left; width: 200px; }
.heightEq { height: 60px; }
.heightEq3 { height: 180px; }
.heightEq20 { height: 20px; }
.or_Head_3 {
	font: Bold 14px Montserrat;
	text-transform: capitalize;
	border-bottom: 1px solid #e8e5e5;
	border-left: 1px solid #e8e5e5;
	border-right: 1px solid #e8e5e5;
	padding-left: 20px;
	padding-top: 20px;
	background: #F5F5F5;
	color: #65778B;
}
.gutterLeft {
	padding-left: 0;
}
.gutterBoth {
	padding-left: 0;
	padding-right: 0;
}

.valueDiv1 {
	font: bold 14px Montserrat;
	padding-left: 20px;
	padding-top: 20px;
	border-bottom: 1px solid #e8e5e5;
    border-right: 1px solid #e8e5e5;
    background: #F5F5F5;
    color: #65778B;
}

.valueDiv {
	font: normal 14px Montserrat;
	padding-left: 20px;
	padding-top: 20px;
	border-bottom: 1px solid #e8e5e5;
    border-right: 1px solid #e8e5e5;
}

.valueDivLabel {
	font: normal 14px Montserrat;
	padding-left: 20px;
	padding-top: 20px;
	border-bottom: 1px solid #e8e5e5;
    border-right: 1px solid #e8e5e5;
    border-left: 1px solid #e8e5e5;
}

.or_Head_1 {
	border-left: 1px solid #e8e5e5;
	border-right: 1px solid #e8e5e5;
	border-bottom: 1px solid #e8e5e5;
}
.mainScrollDiv {
	width: 440px;
    overflow-x: auto;
}

.topborderDiv {
	border-top: 1px solid #e8e5e5;
}

.contentColorDiv:nth-child(even) {
	background-color: #FFFBF8
}